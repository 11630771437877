import React, { FunctionComponent } from 'react'
import classnames from 'classnames'

// default modalClasses =  modal-dialog-centered modal-lg

interface ModalProps {
	isOpen: boolean,
	modalId: string,
	modalClasses?: string
  children: JSX.Element | Array<JSX.Element>
}

const Modal : FunctionComponent<ModalProps> = ({isOpen, modalId, modalClasses, children}) => {
	const style = {display: isOpen ? 'block' : 'none'}

	return(
		<div className={classnames('modal fade', {'show': isOpen})} id={modalId} role="dialog" aria-labelledby={modalId + "-title"} aria-hidden={isOpen} style={style}>
			<div className={'modal-dialog ' + (modalClasses || " modal-dialog-centered modal-lg")} role="document">
				<div className="modal-content">
				  {children}
				</div>
			</div>
		</div>
	)
}

export default Modal

interface ModalHeaderProps {
  title: string
	onClose: any
	modalId: string
  children?: JSX.Element | Array<JSX.Element>
}

export const ModalHeader : FunctionComponent<ModalHeaderProps> = ({title, modalId, onClose, children}) => {
	return(
		<div className="modal-header">
			{children &&
			  <>{children}</>
			}
			<h5 className="modal-title" id={modalId + "-title"}>{title}</h5>
			<button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
				<span aria-hidden="true" className="visually-hidden">&times;</span>
			</button>
		</div>
	)
}

interface ModalFooterProps {
  children: JSX.Element | Array<JSX.Element>
}

export const ModalFooter : FunctionComponent<ModalFooterProps> = ({children}) => {
	return(
		<div className="modal-footer">
			{children}
		</div>
	)
}

interface ModalBodyProps {
  children: JSX.Element | Array<JSX.Element>
}

export const ModalBody : FunctionComponent<ModalBodyProps> = ({children}) => {
	return(
		<div className="modal-body">
			{children}
		</div>
	)
}

