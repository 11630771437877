import React, { useState, useEffect, FunctionComponent } from 'react';
import { createPortal } from 'react-dom';

interface ObjectActionProps {
  event: any,
  objectId: null | number,
  editMode: boolean
  currentUserId: number | null | undefined
}

const ObjectActions = ({event, objectId, currentUserId, editMode, setEditMode, saveEvent, addNewEvent, cancelEdit, deleteEvent, deleteObject}) => {
  const exportData = () => {
    window.location.href = `/objects/${objectId}/export`
  }

  return(
    createPortal(
      <div className="d-flex justify-content-end">
        {currentUserId && editMode &&
          <>
            <div className="btn btn-secondary" onClick={(e) => cancelEdit()}>
              Cancel
            </div>
            <div className="btn btn-primary" onClick={(e) => saveEvent()}>
              Save
            </div>
          </>
        }
        {currentUserId && !editMode &&
          <>
            {event &&
              <div className="btn btn-light" onClick={(e) => setEditMode(!editMode)}>
                Edit Event
              </div>
            }
            <div className="dropdown">
              <button className="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <span className="visually-hidden">Other Actions</span>
                <i className="fa-light fa-angle-down"></i>
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <div className="dropdown-item" onClick={(e) => addNewEvent()}>
                    <i className="dropdown-item__icon fa-light fa-plus"></i>
                    New Event
                  </div>
                </li>
                {event &&
                  <li>
                    <div className="dropdown-item" onClick={(e) => deleteEvent()}>
                      <i className="dropdown-item__icon fa-light fa-minus"></i>
                      Delete Event
                    </div>
                  </li>
                }
                {event &&
                  <li>
                    <a className="dropdown-item" href={'/merge?id=' + objectId} target="_blank">
                      <i className="dropdown-item__icon fa-light fa-merge"></i>
                      Merge...
                    </a>
                  </li>
                }
                {event &&
                  <li>
                    <div className="dropdown-item"  onClick={(e) => exportData()}>
                      <i className="dropdown-item__icon fa-light fa-arrow-down"></i>
                      Export
                    </div>
                  </li>
                }
                <li>
                  <div className="dropdown-item" onClick={(e) => deleteObject()}>
                    <i className="dropdown-item__icon fa-light fa-minus"></i>
                    Delete Object
                  </div>
                </li>
              </ul>
            </div>
          </>
        }
        </div>
      , document.getElementById('topBarActions')
    )
  )
}

export default ObjectActions
