import React, { useState, useEffect, FunctionComponent, Dispatch, SetStateAction } from 'react';

import DropdownSection from './shared/DropdownSection';

import { post } from '../../shared/api/ServerRequest'

// TODO: Allow passing in all options via props (could skip initial server call)
interface KyreniaCatalogueFilterProps {
  filters: any,
  sections: any,
  setFilters: Dispatch<SetStateAction<any>>,
}

// API response
export interface KyreniaCatalogueFilterResponse {
  sections: any
}

const KyreniaCatalogueFilters: FunctionComponent<KyreniaCatalogueFilterProps> = ({filters, setFilters, sections}) => {
  const [showingSections, setShowingSections] = useState<any>([])
  const [optionSearch, setOptionSearch] = useState<any>({})

  const filtersHasValue = (sectionId, value): boolean => {
    const hasOpt = filters[sectionId] && filters[sectionId].some((v) => v == value )
    return !!hasOpt
  }

  const toggleFilter = (sectionId, value) => {
    if(filtersHasValue(sectionId, value)) {
      const newFilters = {...filters}
      newFilters[sectionId] = newFilters[sectionId].filter((v) => v !== value)
      setFilters(newFilters)
    } else if(filters[sectionId]) {
      const newFilters = {...filters}
      newFilters[sectionId].push(value)
      setFilters(newFilters)
    } else {
      const newFilters = {...filters, ...{[sectionId]: [value]}}
      setFilters(newFilters)
    }
  }

  const toggleSection = (sectionId) => {
    if(showingSections.includes(sectionId)) {
      const newSections = showingSections.filter((id) => id != sectionId)
      setShowingSections(newSections)
    } else {
      setShowingSections([...showingSections, sectionId])
    }
  }

  const searchAndOrderOptions = (section): any => {
    let opts = [...section.options]

    if(opts && opts[0].count) {
      const nonZeroResults = opts.filter((opt) => opt.count > 0)
      const zeroResults = opts.filter((opt) => opt.count <= 0)
      opts = [...nonZeroResults, ...zeroResults]
    }

    const search = optionSearch[section.id] || ""
    return opts.filter((opt) => opt.label.toLowerCase().includes(search.toLowerCase()) )
  }

  return(
    <div>
      <div className="mb-2 f-search">
        <i className="icon fa-solid fa-search"></i>
        <input className="form-control" type="text" value={filters.text} onChange={(e) => setFilters({...filters, ...{text: e.target.value}})} placeholder="Catalogue Name" />
      </div>
      {sections.map((section) =>
        <DropdownSection key={`sec-${section.id}`} id={section.id} label={section.label} count={null} toggleSection={toggleSection} showing={showingSections.includes(section.id)}>
          <>
            <div className="mb-3 px-3">
              <div className="f-search">
                <i className="icon fa-solid fa-search"></i>
                <input type="text" className="form-control" value={optionSearch[section.id] || ""} onChange={(e) => setOptionSearch({...optionSearch, [section.id]: e.target.value})} placeholder={`Filter ${section.label}`} />
              </div>
            </div>
            <div className="px-3 scroll-y">
              {searchAndOrderOptions(section).map((option) =>
                <div className="form-check" key={`sec-${section.id}-opt-${option.id}`}>
                  <input className="form-check-input" type="checkbox" onChange={() => toggleFilter(section.id, option.id)} checked={filtersHasValue(section.id, option.id)} />
                  <label className="form-check-label">
                    {option.label} 
                    {option.count &&
                      <span>({option.count})</span>
                    }
                  </label>
                </div>
              )}
            </div>
          </>
        </DropdownSection>
      )}
    </div>
  )
}

export default KyreniaCatalogueFilters