import React, { useState, useEffect, FunctionComponent } from 'react';
import EditEntry from './entry_edit';
import ViewEntry from './entry_view';
import EntryObjects from './entry_objects';

interface ExhibitionEntryProps {
  entry: any
  refresh: Function
  canMoveUp: boolean
  moveUp: Function
  canMoveDown: boolean
  moveDown: Function
}

const ExhibitionEntry: FunctionComponent<ExhibitionEntryProps> = ({ entry, refresh, canMoveUp, moveUp, canMoveDown, moveDown }) => {
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [viewOpen, setViewOpen] = useState<boolean>(false)
  const [objectsOpen, setObjectsOpen] = useState<boolean>(false)

  const closeEntryObjects = (doRefresh: boolean) => {
    if(doRefresh) {
      refresh()
    }
    setObjectsOpen(false)
  }

  const closeEdit = (doRefresh: boolean) => {
    if(doRefresh) {
      refresh()
    }
    setEditOpen(false)
  }

  const closeView = () => {
    setViewOpen(false)
  }

  return(
    <div className="row align-items-center justify-content-center cycle-justify" key={entry.id}>
      <div className="col-12 col-lg-7 col-xxl-6">
        {entry.image &&
          <div onClick={(e) => setViewOpen(true)}>
            <figure className="ratio ratio-1x1 d-none d-lg-block">
              <img src={entry.image.md_link} className="object-contain" />
            </figure>

            <figure className="d-lg-none">
              <img src={entry.image.md_link} className="w-100" />
            </figure>
          </div>
        }
      </div>
      <div className="col-12 col-lg-4 col-xxl-3 pb-3 pt-lg-5 image-description">
        <div className="show-on-hover">
          <div className="show-on-hover__child mb-1 text-light">
            <span onClick={(e) => setEditOpen(true)} className="me-2">Edit</span>
            {canMoveUp &&
              <>
                <span onClick={(e) => moveUp()} className="mx-2">
                  Up
                  <i className="fa-thin fa-angle-up ms-1"></i>
                </span>
              </>
            }
            {canMoveDown &&
              <>
                <span onClick={(e) => moveDown()} className="mx-2">
                  Move Down
                  <i className="fa-thin fa-angle-down ms-1"></i>
                </span>
              </>
            }
          </div>
          <h3 className="h4 fw-light">{entry.name}</h3>
          {entry.description}
        </div>

        <div className="small border-top mt-4 pt-4 d-none">
          <div className="d-none">
            Show associate name, Link to object/event - Object (KS-xxxx)
          </div>
          {false && entry.has_associated_objects &&
            <div onClick={(e) => setObjectsOpen(true)} className="form-group f-search form-dark">
              <i className="icon fa-solid fa-search"></i>
              <input type="search" placeholder="Search for an object in this image" className="form-control" />
            </div>
          }
        </div>
      </div>
      <EntryObjects entry={entry} isOpen={objectsOpen} closeModal={closeEntryObjects} />
      <EditEntry entry={entry} isOpen={editOpen} closeModal={closeEdit} />
      <ViewEntry entry={entry} isOpen={viewOpen} closeModal={closeView} />
    </div>
  )
}
export default ExhibitionEntry