import docReady from '../dom/doc-ready'

docReady(() => {
  const matches = document.querySelectorAll('[data-toggle="offcanvas"]')
  matches.forEach((match) => {
    match.addEventListener('click', function () {
      const offCanvas = document.querySelectorAll('.offcanvas-collapse')
      offCanvas.forEach((off) => off.classList.toggle('open'))
    })
  })
})