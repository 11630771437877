import React, { useState, useEffect, FunctionComponent } from 'react';
import { createPortal } from 'react-dom';

const Breadcrumbs = ({objectId, backToSearch}) => {
  return(
    createPortal(
      <ol className="breadcrumb">
        <li className="first item-0">
          <a href="#" onClick={(e) => backToSearch()}>Research Database</a>
        </li>
        {objectId ?
          <>
            <li className="item-1">
              <a href="#" onClick={(e) => backToSearch()}>Objects & Artifacts</a>
            </li>
            <li className="last item-2">
              <span>KS-{objectId}</span>
            </li>
          </>
          :
          <li className="last item-1">
            <span>Objects & Artifacts</span>
          </li>
        }
      </ol>
      , document.getElementById('breadcrumb-list')
    )
  )
}

export default Breadcrumbs
