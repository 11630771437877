import React, { useState, useEffect, FunctionComponent } from 'react';
import { createPortal } from 'react-dom';


const ObjectIndexActions = ({exportData, admin}) => {
  return(
    createPortal(
        <div className="d-flex justify-content-end">
          {admin &&
            <a href="/objects/new" className="btn btn-light">
              New Object
            </a>
          }
         </div>
      , document.getElementById('topBarActions')
    )
  )
}

export default ObjectIndexActions
