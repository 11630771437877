import React, { useState, useEffect, FunctionComponent } from 'react';
import ObjectSummary from './show/ObjectSummary';
import FiltersSection from './show/FiltersSection';
import ObjectActions from './show/ObjectActions';
import EventsNav from './show/EventsNav';
import EventData from './show/EventData';
import ImageSlider from './show/ImageSlider';

import encodeQueryData from '../shared/api/encodeQueryData'

import { post, patch, get, del } from '../shared/api/ServerRequest'
import setBodyScrollable from '../shared/ui/bodyScrollable'

interface ObjectShowProps {
  objectId: number
  filters?: any
  sections?: any
  backToSearch: any
  currentUserId?: number | null | undefined
}

const ObjectShow: FunctionComponent<ObjectShowProps> = ({objectId, filters, sections, currentUserId}) => {
  const [editMode, setEditMode] = useState<boolean>(!!currentUserId ? true : false)
  const [objectData, setObjectData] = useState<any>(null)
  const [events, setEvents] = useState<any>([])
  const [initialEventCount, setInitialEventCount] = useState<number>(0)
  const [loadingEvents, setLoadingEvents] = useState<boolean>(false)
  const [eventsPage, setEventsPage] = useState<number>(1)
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const [selectedModalImage, setModalImage] = useState<number | null>(null)
  const [matchingEvents, setMatchingEvents] = useState<number[]|null>(null)
  const [matchingEventsLength, setMatchingEventsLength] = useState<number|null>(null)
  const [editedEventData, setEditedEventData] = useState<any>({})

  const [eventsVisible, setEventsVisible] = useState<boolean>(false)
  const [refsVisible, setRefsVisible] = useState<boolean>(false)

  const handleEventsToggle = () => {
    setEventsVisible((current) => !current);
    setRefsVisible(false)
    setBodyScrollable(eventsVisible);
  }

  const handleRefsToggle = () => {
    setRefsVisible((current) => !current);
    setEventsVisible(false)
    setBodyScrollable(refsVisible);
  }

  const setBodyScrolling = (bodyScrollable) => {
    if (!bodyScrollable) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }

  const saveEvent = () => {
    patch(`/event/${editedEventData.event_id}`, {event: editedEventData}).then((response) => {
      // Remove any events that were being edited
      setEvents((prevEvents) => prevEvents.filter((e) => e.id !== -1))
      reloadEvent(response.event.event_id)
      setSelectedEvent(response.event)
      setEditMode(false)
    })
  }

  const deleteEvent = () => {
    if(confirm("Are you sure you want to delete this event?")) {
      del(`/event/${selectedEvent.id}`).then((response) => {
        // Remove any events that were being edited
        setEvents((prevEvents) => prevEvents.filter((e) => e.id != response.event.event_id))
        setSelectedEvent(null)
        setObjectData((prevData) => { return {...prevData, event_count: prevData.event_count - 1} })
      })
    }
  }

  const deleteObject = () => {
    if(objectData.event_count > 0) {
      alert("You can't delete an object that still has events, please delete or merge all events first.")
      return
    }
    if(confirm("Are you sure you want to delete this object?")) {
      del(`/objects/${objectData.id}`).then((response) => {
        window.location.href = "/objects"
      })
    }
  }

  const cancelEdit = () => {
    const newEvents = events.filter((e) => e.id !== -1)
    setEvents(newEvents)
    setEditMode(false)
    setSelectedEvent(null)
  }

  const addNewEvent = () => {
    const newEvent = {
      id: -1,
      event_id: -1,
      obj_id: objectData.id,
      event_type_string: "New",
      images: []
    }
    setEditedEventData(newEvent)
    setEvents([...events, newEvent])
    setSelectedEvent(newEvent)
    setEditMode(true)
  }

  const loadMoreEvents = () => {
    if(events.length >= objectData.event_count) { return }

    post(`/objects/${objectId}/events.json`, {page: eventsPage+1, offset: initialEventCount}).then((response) => {
      setEvents([...events, ...response.events]);
      if(selectedEvent == null && matchingEvents && matchingEvents.length > 0) {
        const event = response.events.find((e) => e.id === matchingEvents[0])
        if(event) { setSelectedEvent(event) }
      }
      setEventsPage(eventsPage+1);
      setLoadingEvents(false);
    })
  }

  const reloadEvent = (eventId) => {
    return get(`/event/${eventId}.json`).then((response) => {
      if(events.find((ev) => ev.id === eventId)) {
        if(selectedEvent && selectedEvent.id === eventId) {
          setSelectedEvent(response.event)
        }
        setEvents((prevEvents) => prevEvents.map((ev) => ev.id == eventId ? response.event : ev))
      } else {
        setEvents((prevEvents) => [...prevEvents, response.event]);
        setObjectData((prevData) => { return {...prevData, event_count: prevData.event_count + 1} })
      }
    })
  }

  useEffect(() => {
    if(selectedEvent) {
      setEditedEventData(selectedEvent)
    } else {
      setEditedEventData({})
    }
  }, [selectedEvent])

  const loadObject = () => {
    return post(`/objects/${objectId}/show.json`, {filters: filters}).then((response) => {
      setEditMode(false)
      setObjectData(response.object)
      setMatchingEvents(response.matching_events)
      setEventsPage(0)
      setEvents(response.events)
      setLoadingEvents(false);
      setInitialEventCount(response.events.length)

      if(selectedEvent == null) {
        if(response.events && response.events.length > 0) {
          if(response.matching_events && response.matching_events.length > 0 ) {
            const event = response.events.find((e) => e.id === response.matching_events[0])
            if(event) { setSelectedEvent(event) }
            setMatchingEventsLength(response.matching_events.length)
          } else {
            setSelectedEvent(response.events[0])
            setMatchingEventsLength(0)
          }
        }
      }
    })
  }

  useEffect(() => {
    if(initialEventCount && initialEventCount >= 5) {
      loadMoreEvents()
    }
  }, [initialEventCount])

  useEffect(() => {
    loadObject()
    // Update the window URL with the filters
    const data = encodeQueryData(filters)
    let url = `/objects/${objectId}`;
    if(data.length > 0) { url = url + "?" + data }
    window.history.pushState({filters: filters, page: 'show', objectId: objectId}, "", url);
  }, [objectId])

  if(objectData == null) { return null }

  return(
    <>
      <div className="grid grid--col-start grid--col-end">
        <div className="grid-nav">
          <div className={`grid-nav__item ${ eventsVisible ? 'bg-primary text-white' : '' }`} onClick={handleEventsToggle}>
            <div className="icon">
              <i className="fa-light fa-list"></i>
            </div>
            <div className="label">
              Events
            </div>
          </div>
          <div className={`grid-nav__item ${ refsVisible ? 'bg-primary text-white' : '' }`}  onClick={handleRefsToggle}>
            <div className="icon">
              <i className="fa-light fa-image"></i>
            </div>
            <div className="label">
              References
            </div>
          </div>
        </div>
        <div className={`grid__left grid__overlay ${eventsVisible ? 'grid__overlay-show' : ''}`} onClick={handleEventsToggle}>
          <FiltersSection objectData={objectData} filters={filters} sections={sections} matchingEventsLength={matchingEventsLength} />
          <EventsNav
            objectData={objectData}
            events={events}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            matchingEvents={matchingEvents}
            loadingEvents={loadingEvents}
            loadMoreEvents={loadMoreEvents}
            totalEvents={objectData ? objectData.event_count : 0}
          />
        </div>
        <EventData
          event={selectedEvent}
          setModalImage={setModalImage}
          setSelectedEvent={setSelectedEvent}
          editMode={editMode}
          saveEvent={saveEvent}
          editedEventData={editedEventData}
          setEditedEventData={setEditedEventData}
          reloadEvent={reloadEvent}
          refsVisible={refsVisible}
          admin={!!currentUserId}
        />
        {objectData && objectData.event_count == 0 && !!currentUserId &&
          <div className="row" style={{marginTop: "90px"}}>
            <div className="col-4"></div>
            <div className="col-8">
              This Object has no events. Click the grey button at the top right with the dropdown arrow to add events and to access other actions.
            </div>
          </div>
        }
      </div>

      <ImageSlider event={selectedEvent} selectedImage={selectedModalImage} setSelectedImage={setModalImage} />

      <ObjectActions
        event={selectedEvent}
        deleteEvent={deleteEvent}
        objectId={objectId}
        currentUserId={currentUserId}
        editMode={editMode}
        setEditMode={setEditMode}
        saveEvent={saveEvent}
        addNewEvent={addNewEvent}
        cancelEdit={cancelEdit}
        deleteObject={deleteObject}
      />
    </>
  )
}
export default ObjectShow