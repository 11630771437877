import React, {FunctionComponent, useEffect, useState} from 'react';
import classNames from 'classnames';
import { post, get } from '../../shared/api/ServerRequest';

import Autocomplete from '../../shared/ui/bootstrap/autocomplete';
import Modal, {ModalHeader, ModalBody, ModalFooter} from '../../shared/ui/bootstrap/modal';

import debounce from '../../shared/ui/debouce';

interface ExhibitionAddProps {
  image: any
  isOpen: boolean
  closeModal: any
  catalogueId?: number
  eventId?: number
  objectId?: number
}

const ExhibitionAdd : FunctionComponent<ExhibitionAddProps> = ({closeModal, image, catalogueId, eventId, objectId, isOpen}) => {
  const [exhibitions, setExhibitions] = useState<any>([])
  const [selectedExhibitionId, setSelectedExhibitionId] = useState<number | string | undefined>("")
  const [selectedSectionId, setSelectedSectionId] = useState<number | string | undefined>("")
  const [entryName, setEntryName] = useState<string>("")
  const [entryDescription, setEntryDescription] = useState<string>("")
  const [newExhibitionName, setNewExhibitionName] = useState<string>("")
  const [newSectionName, setNewSectionName] = useState<string>("")

  const addImageToExhibition = () => {

    const data = {
      image_id: image.id,
      exhibition_id: selectedExhibitionId,
      new_exhibition_name: newExhibitionName,
      section_id: selectedSectionId,
      new_section_name: newSectionName,
      name: entryName,
      description: entryDescription,
      catalogue_id: catalogueId,
      event_id: eventId,
      object_id: objectId
    }
    post('/exhibitions/add_image', data).then((result) => {
      closeModal()
    })
  }

  useEffect(() => {
    setSelectedExhibitionId("")
    setSelectedSectionId("")
    setNewExhibitionName("")
    setNewSectionName("")
    setEntryName("")
    setEntryDescription("")
    get('/exhibitions/list').then((results) => {
      setExhibitions(results.exhibitions)
    })
  }, [image])

  const selectedExhibition = exhibitions.find((ex) => ex.id == selectedExhibitionId)

  return(
    <Modal isOpen={isOpen} modalId="exhibitions-search" modalClasses="modal-fullscreen">
      <ModalHeader modalId="exhibitions-search" onClose={(e) => closeModal(false)}  title="Exhibitions"></ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-lg-3">
            <div className="f-card">
              <div className="px-3 mb-2">
                <div className="f-card__heading p-3 d-flex justify-content-between align-items-center">
                  <span className="f-card__heading-label">Entry Information</span>
                </div>
                <div className="form-floating mb-3">
                  <input className="form-control" value={entryName} onChange={(e) => setEntryName(e.target.value)} />
                  <label>Name</label>
                </div>
                <div className="form-floating mb-3">
                  <textarea className="form-control" value={entryDescription} onChange={(e) => setEntryDescription(e.target.value)} style={{'height': '5rem'}}/>
                  <label>Description</label>
                </div>
              </div>

              <div className="f-card__heading p-3 d-flex justify-content-between align-items-center">
                <span className="f-card__heading-label">Add to Exisiting Exhibition</span>
              </div>

              <div className="px-3 mb-2">
                <div className="form-floating mb-3">
                  <select className="form-control" value={selectedExhibitionId} onChange={(e) => setSelectedExhibitionId(e.target.value)}>
                    <option value=""></option>
                    {exhibitions.map((ex) =>
                      <option key={ex.id} value={ex.id}>{ex.title}</option>
                    )}
                  </select>
                  <div>
                    {selectedExhibitionId === "new" &&
                      <input className="form-control" value={newExhibitionName} onChange={(e) => setNewExhibitionName(e.target.value)} placeholder="New Exhibition Name" />
                    }
                  </div>
                  <label>Select Exhibition</label>
                </div>
              </div>

              {selectedExhibition && false &&
                <div className="px-3 mb-2">
                  <div className="form-floating mb-3">
                    <select className="form-control" value={selectedSectionId} onChange={(e) => setSelectedSectionId(e.target.value)}>
                      <option value=""></option>
                      {selectedExhibition.sections.map((sec) =>
                        <option value={sec.id}>{sec.title}</option>
                      )}
                      <option value="new">New Section</option>
                    </select>
                    <label>Select a Section</label>
                  </div>
                  {selectedSectionId === "new" &&
                    <div className="form-floating mb-3">
                      <input className="form-control" value={newSectionName} onChange={(e) => setNewSectionName(e.target.value)}  />
                      <label htmlFor="">New Section Title</label>
                    </div>
                  }
                </div>
              }
            </div>
          </div>

          <div className="col-lg-9">
            {image &&
              <span key={image.file_name}>
                {["image", "pdf"].includes(image.type) &&
                  <>
                    <figure key={image.md_link}>
                      <img src={image.md_link} />
                    </figure>
                  </>
                }
                {!["image", "pdf"].includes(image.type) &&
                  <>
                    <figure key={image.md_link}>
                      <a href={image.file_link}>
                        <img src={image.md_link} />
                      </a>
                    </figure>
                  </>
                }
              </span>
            }
          </div>
        </div>


      </ModalBody>
      <ModalFooter>
        <button onClick={(e) => closeModal(false)} className="btn btn-light">Cancel</button>
        <button onClick={(e) => addImageToExhibition()} className="btn btn-primary">Add To Exhibition</button>
      </ModalFooter>
    </Modal>
  )
}

export default ExhibitionAdd