import React, { FC, Dispatch, SetStateAction, useState } from 'react';
import classnames from 'classnames'

// TODO: Allow passing in all options via props (could skip initial server call)
interface DropdownSectionProps {
  showing: boolean
  label: string,
  count: number | null,
  id: string,
  toggleSection: Dispatch<SetStateAction<string>>,
  children: JSX.Element,
}

const DropdownSection: FC<DropdownSectionProps> = ({showing, label, count, toggleSection, id, children}) => {
  const toggleSectionClick = (e, id) => {
    if(e) { e.preventDefault() }
    toggleSection(id)
  }

  return(
    <fieldset className="f-select">
      <a href="#"
          onClick={(e) => toggleSectionClick(e, id)}
          className={classnames('f-select__toggle d-flex align-items-center', {collapsed: !showing})}
          aria-expanded={showing ? 'false' : 'true'}
          id={`${id}-button`}
          role="button"
          aria-controls={id}
        >
        <i className="icon fa-solid fa-angle-down me-2 text-muted"></i>
        <div className="f-select__text d-flex justify-content-between flex-grow-1">
          <span className="f-select__label">
            {label}
          </span>
          {count > 0 &&
            <span className="f-select__count fw-normal text-muted">
              ({count})
            </span>
          }
        </div>
      </a>
      <div className={classnames('f-select__menu collapse', {show: showing})} id={id}>
        {children}
      </div>
    </fieldset>
  )
}

export default DropdownSection