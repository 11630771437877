import React from 'react';
import Dropdown from '../../shared/ui/bootstrap/dropdown'

const FiltersSection = ({filters, objectData, matchingEventsLength, sections}) => {

  const sectionFilters = (sectionId) => {
    const section = sections.find((sec) => sec.id === sectionId )
    return filters[sectionId].map((id) => {
      return section.options.find((option) => option.id === id)
    })
  }

  const sectionsWithFilters = (): string[] => {
    return Object.keys(filters).filter((section) => sections.some((s) => s.id == section) && filters[section] && filters[section].length > 0)
  }

  return(
    <>
      <div className="col-header">
        <strong className="me-1">Events</strong>
        {filters && sectionsWithFilters() && matchingEventsLength && matchingEventsLength > 0 ?
          <span className="small">
            <i>({matchingEventsLength} matching / </i>
            <span>{objectData.event_count} total)</span>
          </span>
          :
          <span className="small">({objectData.event_count})</span>
        }
        <Dropdown
          dropdownClass="d-none"
          toggleClass="foo"
          align="dropdown-menu-right">
          <a className="dropdown-item" href="#">
            Action
          </a>
        </Dropdown>
      </div>
      {filters && sectionsWithFilters() &&
        <div className="active-filters border-bottom py-2">
          {filters && sectionsWithFilters().map((section) =>
            <div className="active-filter__section" key={section}>
              <div className="label-text">{section}</div>
              <div className="pills">
                {sectionFilters(section).map((option) =>
                  <div className="pill" key={option.id}>
                    <span className="pill__category">{section}:</span>
                    <span className="pill__close">
                      <i className="icon fa-solid fa-check"></i>
                    </span>
                    <span className="pill__name">{option.label}</span>
                  </div>
                ).reduce((prev, curr) => [prev, <span className="pill__spacer fs-sm text-muted">OR</span>, curr])}
              </div>
            </div>
          )}
          {filters['alias'] && filters['alias'].length > 0 &&
            <div className="active-filter__section">
            <div className="label-text">Alias</div>
            <div className="pills">
              <div className="pill" key={'alias'}>
                <span className="pill__category">Alias:</span>
                <span className="pill__close">
                  <i className="icon fa-solid fa-circle"></i>
                </span>
                <span className="pill__name">{filters['alias']}</span>
              </div>
            </div>
          </div>
          }
        </div>
      }
    </>
  )
}

export default FiltersSection