import React, { useState, useEffect, FunctionComponent } from 'react';
import KyreniaCatalogueFilters from './filters/KyreniaCatalogueFilters'
import KyreniaCatalogueResults from './results/KyreniaCatalogueResults';
import Pagination from './results/Pagination';
import encodeQueryData from '../shared/api/encodeQueryData'
import { post } from '../shared/api/ServerRequest'
import debounce from '../shared/ui/debouce';
import setBodyScrollable from '../shared/ui/bodyScrollable'

interface CatalogueIndexProps {
  initialFilters?: any
  showAlert: boolean
}

const CatalogueIndex: FunctionComponent<CatalogueIndexProps> = ({ showAlert, initialFilters }) => {
  const [results, setResults] = useState<any>(null)
  const [filters, setFilters] = useState<any>(initialFilters || {})
  const [sections, setSections] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)

  const [searchVisible, setSearchVisible] = useState<boolean>(false)

  const handleSearchToggle = () => {
    setSearchVisible((current) => !current);
    setBodyScrollable(searchVisible);
  }

  showAlert = true

  const removeFilterOption = (section, value) => {
    const newFilters = {...filters}
    newFilters[section] = newFilters[section].filter((e) => e !== value)
    if(newFilters[section].length == 0) { delete newFilters[section] }
    setFilters(newFilters)
    showAlert = true
  }

  const sectionFilters = (sectionId) => {
    const section = sections.find((sec) => sec.id === sectionId )
    showAlert = false
    return filters[sectionId].map((id) => {
      return section.options.find((option) => option.id === id)
    })
  }

  const sectionsWithFilters = (): string[] => {
    return Object.keys(filters).filter((section) => sections.some((s) => s.id == section) && filters[section] && filters[section].length > 0)
  }

  useEffect(() => {
    debounce(() => {
      post('/catalogues/results', {filters: filters, page: currentPage}).then((response) => {
        setResults(response)
        setTotalPages(response.pagination.total_pages)
      })

      post('/catalogues/filters', {filters: filters}).then((results) => {
        setSections(results.sections)
      })

      // Update the window URL with the filters
      const data = encodeQueryData(filters)
      let url = window.location.pathname
      if(data.length > 0) { url = url + "?" + data }
      window.history.replaceState(filters, "", url);
    }, 300)
  }, [filters, currentPage])

  return(
    <div className="grid grid--index grid--col-start">

      <div className="grid-nav">
        <div className={`grid-nav__item ${ searchVisible ? 'bg-primary text-white' : '' }`} onClick={handleSearchToggle}>
          <div className="icon">
            <i className="fa-light fa-search"></i>
          </div>
          <div className="label">
            Search
          </div>
        </div>
      </div>

      <div className={`grid__left grid__overlay ${searchVisible ? 'grid__overlay-show' : ''}`}>
        <div className="p-3">
          <KyreniaCatalogueFilters setFilters={setFilters} filters={filters} sections={sections} />
        </div>
      </div>

      <div className="grid__main bg-white">
        <div className="active-filters border-bottom">
          {sectionsWithFilters().map((section) =>
            <div className="active-filter__section" key={section}>
              <div className="label-text">{section}</div>
              <div className="pills">
                {sectionFilters(section).map((option) =>
                  <div className="pill" key={option.id} onClick={(e) => removeFilterOption(section, option.id)}>
                    <span className="pill__category">{section}:</span>
                    <span className="pill__close">
                      <i className="icon fa-solid fa-check"></i>
                    </span>
                    <span className="pill__name">{option.label}</span>
                  </div>
                ).reduce((prev, curr) => [prev, ' OR ', curr])}
              </div>
            </div>
          )}
        </div>
        {results &&
          <div className="px-2">
            <div className="container-fluid pt-3">
              <div className="d-none d-lg-flex alert alert-warning">
                <i className="icon fa-solid fa-search me-3"></i>
                <div> Catalogs contain various images (photos, maps, drawings...) as found. Some, though not all images are associated with Objects in our Research Tool.</div>
              </div>
              <div className="row align-items-stretch">
                <KyreniaCatalogueResults catalogues={results.catalogues} />
                <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
export default CatalogueIndex