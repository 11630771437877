import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

interface EventsListProps {
  events: Array<any>
  loadMoreEvents: Function
  loadingEvents: boolean
  column: string
  moveEvent: Function
}

const EventsList: FunctionComponent<EventsListProps> = (opts) => {
  const {events, loadMoreEvents, loadingEvents, moveEvent} = opts
  const {column} = opts

  const handleScroll = (e) => {
    const node = e.target
    const bottom = Math.abs(node.scrollHeight - (node.scrollTop + node.clientHeight)) <= 1
    if (bottom) {
      loadMoreEvents()
    }
  }

  return(
    <div>
      <div className="scroll-y p-0" onScroll={handleScroll}>
        {events.map((event) =>
          <div className="d-flex justify-content-between align-items-start border-bottom px-o py-3" key={event.id}>
            {column == "right" &&
              <div className="btn btn-outline-dark btn-sm" onClick={() => moveEvent(event)}>
                <i className="icon fa-solid fa-chevron-left"></i>
                <span className="d-none d-xl-inline ms-2">Merge</span>
              </div>
            }
            <div className="flex-grow-1">
              <div className={ column == "left" ? "pe-4" : "ps-4" }>
                <div>
                  <strong>{event.event_type_string}</strong> - ALIAS: {event.alias}
                </div>
                <div className="meta-data"  style={{fontSize: '0.75rem'}}>
                  {event.event_end_formatted} / {event.obj_type_string} / {event.obj_material_string} / {event.obj_origin_string}
                </div>

                { event.images.length > 0 &&
                  <div className="scroll-x media media--sm d-flex justify-content-start mt-2">
                    {event.images.slice(0,7).map((img, index) =>
                      <div className="media__item me-2" key={img.file_name}>
                        {["image", "pdf"].includes(img.type) &&
                          <>
                            <figure key={img.md_link}>
                              <a href={img.md_link} target="_blank">
                                <img src={img.md_link} className="w-100" />
                              </a>
                            </figure>
                          </>
                        }
                        {!["image", "pdf"].includes(img.type) &&
                          <>
                            <figure key={img.md_link}>
                              <a href={img.file_link} target="_blank">
                                <img src={img.md_link} className="w-100" />
                              </a>
                            </figure>
                          </>
                        }
                      </div>
                    )}
                  </div>
                }
              </div>
            </div>
            {column == "left" &&
              <div className="btn btn-outline-dark btn-sm" onClick={() => moveEvent(event)}>
                <span className="d-none d-xl-inline me-2">Merge</span>
                <i className="icon icon--right fa-solid fa-chevron-right"></i>
              </div>
            }
          </div>
        )}
        {loadingEvents &&
          <div className="fa fa-spinner fa-spin fa-2x"></div>
        }
      </div>
    </div>
  )
}

export default EventsList