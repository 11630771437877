import React, { useState, useEffect, FunctionComponent } from 'react';

interface KyreniaCatalogueResultsProps {
  catalogues: Array<any>
}

const image_wrapper_class = "ratio ratio-4x3 rounded-top"

const KyreniaCatalogueResults: FunctionComponent<KyreniaCatalogueResultsProps> = ({catalogues}) => {

  const filteredCatalogues = catalogues.filter ((cat) => cat.image_count > 0)
  return(
    <>
      {filteredCatalogues.map((cat) =>
        <div key={cat.id} className="col-6 col-md-4 col-xl-3 col-xxl-2 mb-3">
          <a href={`/catalogues/${cat.id}`} className="text-decoration-none text-black d-block rounded h-100 border" style={{boxShadow:'0 .1rem .15rem rgba(0,0,0,.1'}}>
            <figure className="position-relative mb-0">
              {cat.primary_image && cat.primary_image.md_link &&
                <div className={image_wrapper_class}>
                  <img src={cat.primary_image.md_link} className="rounded-top object-cover object-position-top" lazy="true"/>
                </div>
              }
              {!(cat.primary_image && cat.primary_image.md_link) &&
                <div className={image_wrapper_class} style={{backgroundColor: '#aab6cd'}}></div>
              }
            </figure>
            <div className="p-3 border-top rounded-bottom">
              <div className="mb-1">
                <span className="fw-bold">Catalogue ID: {cat.id}</span>
              </div>
              <div className="small">
                <div>{cat.image_count} Pages</div>
                <div>{cat.name}</div>
              </div>
            </div>
          </a>
        </div>
      )}
    </>
  )
}

export default KyreniaCatalogueResults