import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

const EventsNav: FunctionComponent<any> = (opts) => {
  const {events, selectedEvent, setSelectedEvent, matchingEvents, loadMoreEvents, loadingEvents, totalEvents} = opts

  const isMatching = (eventId) => {
    if(!matchingEvents) {
      return true
    } else {
      return matchingEvents.includes(eventId)
    }
  }

  const isActive = (event) => {
    if (selectedEvent && selectedEvent.id == event.id) {
      return true
    } else {
      return false
    }
  }

  return(
    <div className="grid__scroller">
      <ul className="nav nav-pills nav--detail flex-column" id="eventTabs" role="tablist">
        {events.map((event) =>
          <li className="nav-item" key={event.id}>
            <a
              className={classNames('nav-link d-flex justify-content-between align-items-center', {
                'active': isMatching(event.id) && isActive(event),
                'text-muted': !isMatching(event.id) && !isActive(event)
              })}
              href="#"
              type="button"
              role="tab"
              onClick={(e) => { e.preventDefault(); setSelectedEvent(event);}}
            >
              { isMatching(event.id) &&
                <i className="icon icon--left fa-solid fa-circle" style={{fontSize: '0.5rem'}}></i>
              }
              <div>
                <div>
                  <strong>{event.event_type_string}</strong> - ALIAS: {event.alias}
                </div>
                <div className="meta-data"  style={{fontSize: '0.75rem'}}>
                  {event.event_end_formatted} / {event.obj_type_string} / {event.obj_material_string} / {event.obj_origin_string}
                </div>
              </div>
              { isActive(event) &&
                <i className="icon icon--right fa-solid fa-chevron-right"></i>
              }
            </a>
          </li>
        )}
      </ul>
      {totalEvents > events.length &&
        <div className="d-flex justify-content-center my-4">
          <div className="btn btn-outline-secondary" onClick={() => loadMoreEvents()}>
            Load More Events
          </div>
        </div>
      }
      {loadingEvents &&
        <div className="fa fa-spinner fa-spin fa-2x"></div>
      }
    </div>
  )
}

export default EventsNav