import React, { FC, useState} from "react";
import classnames from "classnames"
import useClickOutside from "../click-outside"

interface DropdownProps {
  dropdownClass: string
  dropdownIcon: string
  align: string
  toggleClass: string
  children: any
}

const Dropdown : FC<DropdownProps> = ({dropdownClass, dropdownIcon, toggleClass, align, children}) => {
  const setIcon = dropdownIcon === "undefined" ? "fa-filter" : dropdownIcon
  const setDropdownClass = dropdownClass === "undefined" ? "" : dropdownClass
  const setAlign = align === "undefined" ? "dropdown-menu-left" : align
  const setToggleClass = toggleClass === "undefined" ? "btn btn-default" : toggleClass
  const [dropdownOpen, toggleDropdown] = useState(false)

  const dropdownClick = () => {
    toggleDropdown(!dropdownOpen)
  }

  const dropdownRef = React.useRef(null)
  useClickOutside(dropdownRef, () => {
    toggleDropdown(false)
  })

  return (
    <div className={classnames("dropdown", setDropdownClass, {"show": dropdownOpen})} ref={dropdownRef}>
      <div className={classnames("dropdown-toggle", setToggleClass)} role="button" aria-haspopup="true" aria-expanded="false" onClick={dropdownClick}>
        <i className={classnames("fa ", setIcon)} title="Filter"></i>
      </div>
      <ul className={classnames("dropdown-menu", setAlign, {"show": dropdownOpen})}>
        {children}
      </ul>
    </div>
  )
}

export default Dropdown