// Utilities to make HTTP requests to an API. Includes code to send the Rails CSRF token

export const request = (url, data = null, opts = {}, requestOpts = {}) => {
  const defaultOpts = {
    method: 'POST',
    headers: { 
		  'Accept': 'application/javascript', 
		  'Content-Type': 'application/json',
		  'X-CSRF-Token': getMetaTag('csrf-token')
    }
  }

  if(requestOpts.rawBody && data) {
    defaultOpts.body = data
  } else if(data) {
  	defaultOpts.body = JSON.stringify(data)
  }

  const mergedOpts = {...defaultOpts, ...opts}

  if(requestOpts['noContentType']) {
    delete mergedOpts.headers['Content-Type'];
    delete mergedOpts.headers['Accept'];
  }

  //console.log("Fetching: " + url)
  //console.log(mergedOpts)

  return fetch(url, mergedOpts).then((response) => {
    if(requestOpts.rawResponse) {
      return response.text().then((body) => {
      //  console.log('Fetch Response: ' + body)
        return body
      }).catch((err) => {
        console.log('Fetch problem: ' + err.message)
        // TODO: Need to alert here
        throw err
      })
    } else {
      return response.json().then((json) => {
      // 	console.log('Fetch Response: ' + json)
      	return json
      }).catch((err) => {
      	console.log('Fetch problem: ' + err.message)
      	// TODO: Need to alert here
      	throw err
      })
    }
  })
}

export const post = (url, data) => {
  return request(url, data, {method: 'POST'})
}

export const postFormData = (url, formData) => {
  const opts = {
    method: 'POST',
  }
  return request(url, formData, opts, {rawBody: true, noContentType: true})
}

export const get = (url) => {
  return request(url, null, {method: 'GET'})
}

export const getRaw = (url) => {
  return request(url, null, {method: 'GET'}, {rawRespnse: true})
}

export const patch = (url, data) => {
  return request(url, data, {method: 'PATCH'})
}

export const del = (url, data) => {
  return request(url, data, {method: 'DELETE'})
}

function getMetaTag(metaName) {
  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) {
      return metas[i].getAttribute('content');
    }
  }

  return '';
}