import { Modal } from 'bootstrap';
import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { post, patch, del } from '../shared/api/ServerRequest'
import EventsList from './merge/EventsList';

const MergeObjects : FunctionComponent<any> = ({initialLeftObjectId, initialRightObjectId}) => {
  const [leftObjectId, setLeftObjectId] = useState<number|null>(initialLeftObjectId)
  const [leftObject, setLeftObject] = useState<any>({})

  const [rightObjectId, setRightObjectId] = useState<number|null>(initialRightObjectId)
  const [rightObject, setRightObject] = useState<any>({})

  const [leftObjectEvents, setLeftObjectEvents] = useState<any>([])
  const [leftEventsPage, setLeftEventsPage] = useState<number>(1)
  const [rightObjectEvents, setRightObjectEvents] = useState<any>([])
  const [rightEventsPage, setRightEventsPage] = useState<number>(1)

  const loadObject = (id) => {
    return post(`/objects/${id}/show.json`, {})
  }

  useEffect(() => {
    if(leftObjectId) {
      loadLeftObject()
    }
  }, [initialLeftObjectId])

  const loadMoreEvents = (events, objectData, page) => {
    if(events.length >= objectData.event_count) { return }

    post(`/objects/${objectData.id}/events.json`, {page: page+1}).then((response) => {
      if(objectData.id === leftObject.id) {
        setLeftObjectEvents([...events, ...response.events]);
        setLeftEventsPage(page+1);
      } else if(objectData.id == rightObject.id) {
        setRightObjectEvents([...events, ...response.events]);
        setRightEventsPage(page+1);
      }
    })
  }

  const loadLeftObject = () => {
    loadObject(leftObjectId).then((resp) => {
      setLeftObject(resp.object)
      setLeftObjectEvents(resp.events)
      setLeftEventsPage(1)
    }).catch((err) => {
      alert("object not found")
    })
  }

  const loadRightObject = () => {
    loadObject(rightObjectId).then((resp) => {
      setRightObject(resp.object)
      setRightObjectEvents(resp.events)
      setRightEventsPage(1)
    }).catch((err) => {
      alert("object not found")
    })
  }

  const deleteObject = (id) => {
    if(confirm("Are you SURE you want to delete this object?")) {
      del(`/objects/${id}`).then((resp) => {
        setRightObject(null)
        setRightObjectEvents([])
        setRightEventsPage(1)
      })
    }
  }

  const moveEventToLeftObject = (event) => {
    post('/events/move', {destination: leftObject.id, event_id: event.id}).then((response) => {
      setLeftObjectEvents([event, ...leftObjectEvents])
      setRightObjectEvents(rightObjectEvents.filter((ev) => ev.id !== event.id))
    })
  }

  const moveEventToRightObject = (event) => {
    post('/events/move', {destination: rightObject.id, event_id: event.id}).then((response) => {
      setRightObjectEvents([event, ...rightObjectEvents])
      setLeftObjectEvents(leftObjectEvents.filter((ev) => ev.id !== event.id))
    })
  }

  return(
    <div className="container-fluid pt-4">
      <div className="card p-4 mb-4">
        <h1 className="h6">Merge or Delete Objects</h1>
        <ol className="mb-0">
          <li>Enter an object id in the left input and click "Load Object"</li>
          <li>Enter an object id in the right input and click "Load Object"</li>
          <li>Move events from one object to the other by clicking the "Move" button on the event</li>
          <li>You can also delete an object by clicking the "Delete Object" button</li>
        </ol>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="card">
            <div className="p-3">
              <div className="row">
                <div className="col-md-6 col-xl-9">
                  <input placeholder="Object ID" className="form-control" value={leftObjectId ? leftObjectId.toString() : ""} onChange={(e) => setLeftObjectId(parseInt(e.target.value)) }/>
                </div>
                <div className="col-md-6 col-xl-3">
                  <button onClick={(e) => loadLeftObject() } className="btn btn-primary btn-block w-100">
                    Load&nbsp;Object
                  </button>
                </div>
              </div>
            </div>

            {leftObject && leftObject.id &&
              <div className="border-top">
                <div className="p-3 border-bottom">
                  <div>
                    <strong>Object ID {leftObject.id}</strong>
                  </div>
                  <div>{leftObject.obj_desc}</div>
                </div>
                <div className="px-3">
                  <EventsList
                    column="left"
                    events={leftObjectEvents}
                    loadingEvents={false}
                    loadMoreEvents={() => loadMoreEvents(leftObjectEvents, leftObject, leftEventsPage)}
                    moveEvent={moveEventToRightObject}
                  />
                </div>
                <div className="p-3 bg-danger-200 border-top text-center">
                  <button onClick={() => deleteObject(leftObject.id)} className="btn btn-outline-danger">Delete Object</button>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="col-6">
          <div className="card">
            <div className="p-3">
              <div className="row">
                <div className="col-md-6 col-xl-9">
                  <input placeholder="Object ID" className="form-control" value={rightObjectId ? rightObjectId.toString() : ""} onChange={(e) => setRightObjectId(parseInt(e.target.value)) }/>
                </div>
                <div className="col-md-6 col-xl-3">
                  <button onClick={(e) => loadRightObject() } className="btn btn-primary btn-block w-100">
                    Load&nbsp;Object
                  </button>
                </div>
              </div>
            </div>

            {rightObject && rightObject.id &&
              <div className="border-top">
                <div className="p-3 border-bottom">
                  <div>
                    <strong>Object ID {rightObject.id}</strong>
                  </div>
                  <div>{rightObject.obj_desc}</div>
                </div>
                <div className="px-3">
                  <EventsList
                    column="right"
                    events={rightObjectEvents}
                    loadingEvents={false}
                    loadMoreEvents={() => loadMoreEvents(rightObjectEvents, rightObject, rightEventsPage)}
                    moveEvent={moveEventToLeftObject}
                  />
                </div>
                <div className="p-3 bg-danger-200 border-top text-center">
                  <button onClick={() => deleteObject(rightObject.id)} className="btn btn-outline-danger">Delete Object</button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default MergeObjects