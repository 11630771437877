import React from 'react';
import classNames from 'classnames';

const ActiveFilters = ({filters, sections, removeFilterOption, setFilters}) => {
  const sectionsWithFilters = (): string[] => {
    return Object.keys(filters).filter((section) => sections.some((s) => s.id == section) && filters[section] && filters[section].length > 0)
  }

  const sectionFilters = (sectionId) => {
    const section = sections.find((sec) => sec.id === sectionId )
    return filters[sectionId].map((id) => {
      return section.options.find((option) => option.id === id)
    })
  }

  return(
    <>
      {sectionsWithFilters().map((section) =>
        <div className="active-filter__section" key={section}>
          <div className="label-text">{section}</div>
          <div className="pills">
            {sectionFilters(section).map((option) =>
              <div className="pill" key={option.id} onClick={(e) => removeFilterOption(section, option.id)}>
                <span className="pill__category">{section}:</span>
                <span className="pill__close">
                  <i className="icon fa-solid fa-check"></i>
                </span>
                <span className="pill__name">{option.label}</span>
              </div>
            ).reduce((prev, curr) => [prev, <span className="pill__spacer fs-sm text-muted">OR</span>, curr])}
          </div>
        </div>
      )}
      {filters['alias'] && filters['alias'].length > 0 &&
        <div className="active-filter__section">
        <div className="label-text">Alias</div>
        <div className="pills">
          <div className="pill" key={'alias'} onClick={() => setFilters({...filters, ...{alias: ''}}) }>
            <span className="pill__category">Alias:</span>
            <span className="pill__close">
              <i className="icon fa-solid fa-check"></i>
            </span>
            <span className="pill__name">{filters['alias']}</span>
          </div>
        </div>
      </div>
      }
      {(filters['event_before'] || filters['event_after']) &&
        <div className="active-filter__section">
          <div className="label-text">Event Dates</div>
          <div className="pills">
            <div className="pill" key={'dates'}>
              <span className="pill__category">Event Dates:</span>
              <span className="pill__close">
                <i className="icon fa-solid fa-check"></i>
              </span>
              {filters['event_after'] &&
                <span className="pill__name">After {(new Date(filters['event_after'])).toLocaleDateString()}</span>
              }
              {filters['event_after'] && filters['event_before'] &&
                <span>and&nbsp;</span>
              }
              {filters['event_before'] &&
                <span className="pill__name">Before {(new Date(filters['event_before'])).toLocaleDateString()}</span>
              }
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default ActiveFilters