import React, { useState, useEffect, FunctionComponent } from 'react';
import ExhibitionAdd from './show/ExhibitionAdd';

interface CatalogueShowProps {
  imageList: Array<any>
  catalogue: any
  currentUserId?: number | null | undefined
}

const CatalogueShow: FunctionComponent<CatalogueShowProps> = ({ imageList, catalogue, currentUserId }) => {
  const [images, setImages] = useState<any>(imageList)
  const [selectedImage, setSelectedImage] = useState<any>(null)

  return(
    <div className="page-gutter">
      <div className="container-fluid pt-4">
        <header className="mb-4 d-none">
          <h1 className="h5">{catalogue.name}</h1>
        </header>

        <div className="row">
          {images.map((image) =>
            <div key={image.id} className="col-6 col-md-4 col-lg-3 col-xxl-2 mb-4">
              <div className="cardd">
                <a href={image.file_link} target="_blank" rel="noreferrer">
                  <figure className="ratio ratio-1x1 mb-0">
                    <img src={image.md_link} className="object-contain object-position-middle" />
                  </figure>
                </a>
              </div>
              {currentUserId &&
                <div className="py-2">
                  <a href="#" onClick={(e) => { e.preventDefault(); setSelectedImage(image);}}>Add To Exhibition</a>
                </div>
              }
            </div>
          )}
        </div>
      </div>
      {currentUserId && selectedImage &&
        <ExhibitionAdd isOpen={selectedImage} closeModal={() => setSelectedImage(null)} image={selectedImage} catalogueId={catalogue.id} />
      }
    </div>
  )
}

export default CatalogueShow