import React, { FunctionComponent, useEffect, useState } from 'react';
import { post, get } from '../../shared/api/ServerRequest';
import Autocomplete from '../../shared/ui/bootstrap/autocomplete';

//import DatePicker from "react-datepicker";
//import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const EventTableEdit: FunctionComponent<any> = ({event, saveEvent, eventData, setEventData}) => {
  if(event == null || !eventData) { return null }
  const [editData, setEditData] = useState<any>({})

  const mergeEventData = (data) => {
    setEventData({...eventData, ...data})
  }

  const getEditData = () => {
    get('/events/edit_data').then((response) => {
      setEditData(response.edit_data)
    });
  }

  useEffect(() => {
    getEditData();
  }, [event])

  const transformDate = (date) => {
    if(!date) { return "" }
    return date.replace(/T.+/g, "");
  }

  return(
    <>
      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Alias</div>
        <div className="dd">
          <Autocomplete options={editData.aliases} placeholderText="" value={eventData.alias || ""} onChange={(value) => mergeEventData({alias: value})} allowNewValues={true} emptyName={""} />
        </div>
      </div>

      {eventData.event_id > 0 &&
        <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
          <div className="dt">Event ID</div>
          <div className="dd">
            {eventData.event_id}
          </div>
        </div>
      }

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Type</div>
        <div className="dd">
          <Autocomplete options={editData.event_types} placeholderText="" value={eventData.event_type_id || ""} onChange={(value) => mergeEventData({event_type_id: value})} emptyName={""} />
        </div>
      </div>

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Site</div>
        <div className="dd">
          <Autocomplete options={editData.sites} placeholderText="" value={eventData.site || ""} onChange={(value) => mergeEventData({site: value})} emptyName={""} />
        </div>
      </div>

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Owner</div>
        <div className="dd">
          <Autocomplete options={editData.associates} placeholderText="" value={eventData.event_assoc || ""} onChange={(value) => mergeEventData({event_assoc: value})} emptyName={""} />
        </div>
      </div>

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Editor</div>
        <div className="dd">
          <Autocomplete options={editData.associates} placeholderText="" value={eventData.event_auth_assoc || ""} onChange={(value) => mergeEventData({event_auth_assoc: value})} emptyName={""} />
        </div>
      </div>

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Start Date</div>
        <div className="dd">
          <input type="date"
            value={transformDate(eventData.event_start || "")}
            onChange={(e) => mergeEventData({event_start: e.target.value})}
            className="form-control"
          />
        </div>
      </div>

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">End Date</div>
        <div className="dd">
          <input type="date"
            value={transformDate(eventData.event_end || "")}
              onChange={(e) => mergeEventData({event_end: e.target.value})}
              className="form-control"
            />
        </div>
      </div>

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Description</div>
        <div className="dd">
          <textarea
            value={eventData.event_description || ""}
            onChange={(e) => mergeEventData({event_description: e.target.value})}
            className="form-control"
          />
        </div>
      </div>

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Object Type</div>
        <div className="dd">
          <Autocomplete options={editData.object_types} placeholderText="" value={eventData.obj_type || ""} onChange={(value) => mergeEventData({obj_type: value})} emptyName={""} />
        </div>
      </div>

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Material</div>
        <div className="dd">
          <Autocomplete options={editData.material_types} placeholderText="" value={eventData.obj_matrl_id || ""} onChange={(value) => mergeEventData({obj_matrl_id: value})} emptyName={""} />
        </div>
      </div>

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Lat / Lng</div>
        <div className="dd">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">lat</span>
            </div>
            <input value={eventData.latitude || ""} onChange={(e) => mergeEventData({latitude: e.target.value})} className="form-control" placeholder="Lat" />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">long</span>
            </div>
            <input value={eventData.longitude || ""} onChange={(e) => mergeEventData({longitude: e.target.value})} className="form-control" placeholder="Lng" />
          </div>
        </div>
      </div>

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Grid (x,y)</div>
        <div className="dd">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">x</span>
            </div>
            <input value={eventData.grid_x || ""} onChange={(e) => mergeEventData({grid_x: e.target.value})} className="form-control" placeholder="X" />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">y</span>
            </div>
            <input value={eventData.grid_y || ""} onChange={(e) => mergeEventData({grid_y: e.target.value})} className="form-control" placeholder="Y" />
          </div>
        </div>
      </div>

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Location (1,2,3)</div>
        <div className="dd">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">1</span>
            </div>
            <input value={eventData.loc_1 || ""} onChange={(e) => mergeEventData({loc_1: e.target.value})} className="form-control" placeholder="1" />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">2</span>
            </div>
            <input value={eventData.loc_2 || ""} onChange={(e) => mergeEventData({loc_2: e.target.value})} className="form-control" placeholder="2" />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">3</span>
            </div>
            <input value={eventData.loc_3 || ""} onChange={(e) => mergeEventData({loc_3: e.target.value})} className="form-control" placeholder="3" />
          </div>
        </div>
      </div>

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Size (w,l,h in cm)</div>
        <div className="dd">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">W</span>
            </div>
            <input value={eventData.width_cm || ""} onChange={(e) => mergeEventData({width_cm: e.target.value})} className="form-control" placeholder="w" />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">L</span>
            </div>
            <input value={eventData.length_cm || ""} onChange={(e) => mergeEventData({length_cm: e.target.value})} className="form-control" placeholder="l" />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">H</span>
            </div>
            <input value={eventData.height_cm || ""} onChange={(e) => mergeEventData({height_cm: e.target.value})} className="form-control" placeholder="h" />
          </div>
        </div>
      </div>

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Thickness (cm)</div>
        <div className="dd">
          <input value={eventData.thickness_cm || ""} onChange={(e) => mergeEventData({thickness_cm: e.target.value})} className="form-control" placeholder="t" />
        </div>
      </div>

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Weight (kg)</div>
        <div className="dd">
          <input value={eventData.weight_kg || ""} onChange={(e) => mergeEventData({weight_kg: e.target.value})} className="form-control" placeholder="w" />
        </div>
      </div>

      <div className="p-2 d-lg-flex align-items-lg-top border-bottom">
        <div className="dt">Capacity (ltr)</div>
        <div className="dd">
          <input value={eventData.capacity_ltr || ""} onChange={(e) => mergeEventData({capacity_ltr: e.target.value})} className="form-control" placeholder="w" />
        </div>
      </div>
    </>
  )
}

export default EventTableEdit
