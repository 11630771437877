import React, { useState, useEffect, FunctionComponent } from 'react';
import { get } from '../shared/api/ServerRequest';

import ExhibitionSection from './exhibitions/section';

interface ExhibitionShowProps {
  exhibitionData: any
}

const ExhibitionShow: FunctionComponent<ExhibitionShowProps> = ({ exhibitionData }) => {
  const [exhibition, setExhibition] = useState<any>(exhibitionData)

  const refresh = () => {
    get(`/exhibition/${exhibition.id}/data`).then((response) => {
      setExhibition(response.exhibition)
    })
  }

  return(
    <>
      {exhibition.sections.map((section) =>
        <section className="mt-5 pt-lg-5" key={section.id}>
          <ExhibitionSection section={section} refresh={refresh} />
        </section>
      )}
    </>
  )
}
export default ExhibitionShow