import React, { useState, useEffect, FunctionComponent } from 'react';

import ExhibitionEntry from './entry';
import EditEntry from './entry_edit';
import ImageSearch from './image_search';
import { post } from '../../shared/api/ServerRequest';

interface ExhibitionSectionProps {
  section: any
  refresh: Function
}

const ExhibitionSection: FunctionComponent<ExhibitionSectionProps> = ({ section, refresh }) => {
  const [addImageOpen, setAddImageOpen] = useState<boolean>(false)

  const closeImageModal = (doRefresh: boolean) => {
    setAddImageOpen(false)
    if(doRefresh) {
      refresh()
    }
  }

  const moveUp = (entry: any) => {
    // Move the entry up in the array of entries
    let entries = section.entries
    let index = entries.indexOf(entry)
    if(index > 0) {
      let temp = entries[index - 1]
      entries[index - 1] = entry
      entries[index] = temp
    }
    const order = entries.map((e: any) => e.id)
    post(`/exhibitions/order_entries`, { section_id: section.id, entries: order}).then((response) => {
      refresh()
    })
  }

  const moveDown = (entry: any) => {
    // Move the entry down in the array of entries
    let entries = section.entries
    let index = entries.indexOf(entry)
    if(index < entries.length - 1) {
      let temp = entries[index + 1]
      entries[index + 1] = entry
      entries[index] = temp
    }
    const order = entries.map((e: any) => e.id)
    post(`/exhibitions/order_entries`, { section_id: section.id, entries: order }).then((response) => {
      refresh()
    })
  }

  return(
    <>
      {section.entries.length > 0 &&
        <div>
          {section.primary_image &&
            <img src={section.primary_image} />
          }
          {section.entries.map((entry, index) =>
            <ExhibitionEntry key={entry.id} entry={entry} refresh={refresh} canMoveUp={index > 0} moveUp={(e) => moveUp(entry)} canMoveDown={index < section.entries.length - 1} moveDown={(e) => moveDown(entry)} />
          )}
          <ImageSearch section={section} isOpen={addImageOpen} closeModal={closeImageModal} />
        </div>
      }
    </>
  )
}
export default ExhibitionSection