import React, {FunctionComponent, useEffect, useState} from 'react';
import classNames from 'classnames';
import { post, get } from '../../shared/api/ServerRequest';

import Modal, {ModalHeader, ModalBody, ModalFooter} from '../../shared/ui/bootstrap/modal';

import debounce from '../../shared/ui/debouce';

interface ViewEntryProps {
  entry: any
  isOpen: boolean
  closeModal: any
}

const ViewEntry : FunctionComponent<ViewEntryProps> = ({closeModal, entry, isOpen}) => {

  return(
    <Modal isOpen={isOpen} modalId="image-view" modalClasses="modal-fullscreen">
      <ModalHeader modalId="image-view" onClose={(e) => closeModal(false)} title={entry.name}></ModalHeader>
      <ModalBody>
        <div key={entry.image.file_name} className="w-100">
          {["image", "pdf"].includes(entry.image.type) &&
            <>
              <figure key={entry.image.md_link}>
                <img src={entry.image.md_link} style={{width: '100%'}} />
              </figure>
            </>
          }
          {!["image", "pdf"].includes(entry.image.type) &&
            <>
              <figure key={entry.image.md_link}>
                <a href={entry.image.file_link}>
                  <img src={entry.image.md_link} style={{width: '100%'}}/>
                </a>
              </figure>
            </>
          }
        </div>
      </ModalBody>
      <ModalFooter>
        <button onClick={(e) => closeModal(false)} className="btn btn-light">Close</button>
      </ModalFooter>
    </Modal>
  )
}

export default ViewEntry