import React from 'react';
import classNames from 'classnames';

const Pagination = ({totalPages, currentPage, setCurrentPage}) => {
  if(totalPages <= 1) { return null }

  const setPage = (e, page) => {
    e.preventDefault();
    setCurrentPage(page)
    window.scrollTo({top: 0, behavior: 'instant'});
  }

  const prevPage = (e) => {
    e.preventDefault();
    if(currentPage > 1) {
      setCurrentPage(currentPage - 1)
      window.scrollTo({top: 0, behavior: 'instant'});
    }
  }

  const nextPage = (e) => {
    e.preventDefault();
    if(currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1)
    }
    window.scrollTo({top: 0, behavior: 'instant'});
  }


  const startPage = currentPage - 4 > 0 ? currentPage - 4 : 1
  const endPage = currentPage + 4 < totalPages ? currentPage + 4 : totalPages
  if(endPage - startPage + 1 <= 1) { return null }

  const pageList = Array.from(Array(endPage - startPage + 1).keys()).map((i) => i + startPage)

  return(
    <nav aria-label="Page navigation">
      <ul className="pagination">
        {currentPage > 1 &&
          <li className="page-item">
            <a className="page-link" href="#" onClick={(e) => prevPage(e)}>Previous</a>
          </li>
        }
        {currentPage - 4 > 1 &&
          <>
            <li className="page-item">
              <a className="page-link" href="#" onClick={(e) => setPage(e, 1)}>1</a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" onClick={(e) => e.preventDefault()}>...</a>
            </li>
          </>
        }
        {pageList.map((page) => 
          <li key={page} className={classNames('page-item', {active: currentPage == page})}>
            <a className="page-link" href="#" onClick={(e) => setPage(e, page)}>{page}</a>
          </li>
        )}
        {totalPages > currentPage + 4 &&
          <>
            <li className="page-item">
              <a className="page-link" href="#" onClick={(e) => e.preventDefault()}>...</a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#" onClick={(e) => setPage(e, totalPages)}>{totalPages}</a>
            </li>
          </>
        }
        {currentPage < totalPages &&
          <li className="page-item">
            <a className="page-link" href="#" onClick={(e) => nextPage(e)}>Next</a>
          </li>
        }
      </ul>
    </nav>
  )
}

export default Pagination
