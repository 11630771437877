import React, { useState, useEffect, FunctionComponent, Dispatch, SetStateAction } from 'react';
import DropdownSection from './shared/DropdownSection';
import { Popover } from 'react-tiny-popover'
import { post } from '../../shared/api/ServerRequest'
import classnames from 'classnames'
import AutocompleteUrl from '../../shared/ui/bootstrap/autocomplete-url';

// TODO: Allow passing in all options via props (could skip initial server call)
interface KyreniaObjectFilterProps {
  filters: any,
  sections: any,
  setFilters: Dispatch<SetStateAction<any>>,
}

// API response
export interface KyreniaObjectFilterResponse {
  sections: any
}

const KyreniaObjectFilters: FunctionComponent<KyreniaObjectFilterProps> = ({filters, setFilters, sections}) => {
  const [showingSections, setShowingSections] = useState<any>([])
  const [optionSearch, setOptionSearch] = useState<any>({})
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false)

  const filtersHasValue = (sectionId, value): boolean => {
    const hasOpt = filters[sectionId] && filters[sectionId].some((v) => v == value )
    return !!hasOpt
  }

  const toggleFilter = (sectionId, value) => {
    if(filtersHasValue(sectionId, value)) {
      const newFilters = {...filters}
      newFilters[sectionId] = newFilters[sectionId].filter((v) => v !== value)
      setFilters(newFilters)
    } else if(filters[sectionId]) {
      const newFilters = {...filters}
      newFilters[sectionId].push(value)
      setFilters(newFilters)
    } else {
      const newFilters = {...filters, ...{[sectionId]: [value]}}
      setFilters(newFilters)
    }
  }

  const toggleSection = (sectionId) => {
    if(showingSections.includes(sectionId)) {
      const newSections = showingSections.filter((id) => id != sectionId)
      setShowingSections(newSections)
    } else {
      setShowingSections([...showingSections, sectionId])
    }
  }

  const searchAndOrderOptions = (section): any => {
    let opts = [...section.options]

    const nonZeroResults = opts.filter((opt) => opt.count > 0)
    const zeroResults = opts.filter((opt) => opt.count <= 0)
    opts = [...nonZeroResults, ...zeroResults]

    const search = optionSearch[section.id] || ""
    return opts.filter((opt) => opt.label.toLowerCase().includes(search.toLowerCase()) )
  }

  const objectSections = sections.filter((s) => s.type == "object" )
  const eventSections = sections.filter((s) => s.type == "event" )

  return(
    <div className="f-card">
      <div className="f-card__heading p-3 d-flex justify-content-between align-items-center">
        <span className="f-card__heading-label">Filters</span>
        <Popover
          isOpen={isFilterPopoverOpen}
          positions={['bottom','left',  'top', 'right']}
          content={<div><div>Select filters below to find museum objects and the archaeological <em>events</em> that researchers recorded for each.</div></div>}
          onClickOutside={() => setIsFilterPopoverOpen(false)}
        >
          <i onClick={() => setIsFilterPopoverOpen(!isFilterPopoverOpen)} className="icon fa-solid fa-info-circle text-secondary"></i>
        </Popover>

      </div>
      <div className="px-3 mb-2 ">
        <div className="f-search">
          <i className="icon fa-solid fa-search"></i>
          <AutocompleteUrl url='/autocomplete/alias' value={filters.alias || ""} onChange={(value) => setFilters({...filters, ...{alias: value}})} placeholderText="Alias" />
        </div>
      </div>
      <div className="px-3 mb-2 ">
        <div className="f-search">
          <i className="icon fa-solid fa-search"></i>
          <AutocompleteUrl url='/autocomplete/object_id' value={filters.text || ""} onChange={(value) => setFilters({...filters, ...{text: value}})} placeholderText="Object ID" />
        </div>
      </div>
      {objectSections.map((section) =>
        <DropdownSection key={`sec-${section.id}`} id={section.id} label={section.label} count={section.options.filter((s) => s.count > 0).length} toggleSection={toggleSection} showing={showingSections.includes(section.id)}>
          <>
            <div className="mb-3 px-3">
              <div className="f-search">
                <i className="icon fa-solid fa-search"></i>
                <input type="text" className="form-control" value={optionSearch[section.id] || ""} onChange={(e) => setOptionSearch({...optionSearch, [section.id]: e.target.value})} placeholder={`Filter ${section.label}`} />
              </div>
            </div>

            <div className="px-3 scroll-y">
              {searchAndOrderOptions(section).map((option) => {
                const isDisabled = option.count == 0 && !filtersHasValue(section.id, option.id) ? true : false
                return (
                  <div
                    className={classnames('form-check', {'': isDisabled })}
                    key={`sec-${section.id}-opt-${option.id}`}
                    >
                    <input className="form-check-input" type="checkbox" id={`sec-${section.id}-opt-${option.id}`} name={`sec-${section.id}-opt-${option.id}`} disabled={isDisabled} onChange={() => toggleFilter(section.id, option.id)} checked={filtersHasValue(section.id, option.id)} />
                    <label className="form-check-label" htmlFor={`sec-${section.id}-opt-${option.id}`}>
                      <span>{option.label}</span>
                      <span className="text-end filter__count">({option.count})</span>
                    </label>
                  </div>
                )}
              )}
            </div>
          </>
        </DropdownSection>
      )}

      {eventSections.map((section) =>
        <DropdownSection key={`sec-${section.id}`} id={section.id} label={section.label} count={section.options.filter((s) => s.count > 0).length} toggleSection={toggleSection} showing={showingSections.includes(section.id)}>
          <>
            <div className="mb-3 px-3">
              <div className="f-search">
                <i className="icon fa-solid fa-search"></i>
                <input type="text" className="form-control" value={optionSearch[section.id] || ""} onChange={(e) => setOptionSearch({...optionSearch, [section.id]: e.target.value})} placeholder={`Filter ${section.label}`} />
              </div>
            </div>

            <div className="px-3 scroll-y">
              {searchAndOrderOptions(section).map((option) => {
                const isDisabled = option.count == 0 && !filtersHasValue(section.id, option.id) ? true : false
                return (
                  <div
                    className={classnames('form-check', {'': isDisabled })}
                    key={`sec-${section.id}-opt-${option.id}`}
                    >
                    <input className="form-check-input" type="checkbox" id={`sec-${section.id}-opt-${option.id}`} name={`sec-${section.id}-opt-${option.id}`} disabled={isDisabled} onChange={() => toggleFilter(section.id, option.id)} checked={filtersHasValue(section.id, option.id)} />
                    <label className="form-check-label" htmlFor={`sec-${section.id}-opt-${option.id}`}>
                      <span>{option.label}</span>
                      <span className="text-end filter__count">({option.count})</span>
                    </label>
                  </div>
                )}
              )}
            </div>
          </>
        </DropdownSection>
      )}

      <div className="px-3 mt-2 ">
        <div className="form-check">
          {/*
          <input type="checkbox" className="form-check-input" name="hide_no_events" checked={filters.hide_no_events || false} onChange={(e) => setFilters({...filters, ...{hide_no_events: e.target.checked}})} />
          <label className="form-check-label">Hide objects with no matching events</label>
          */}
        </div>
      </div>
    </div>
  )
}

export default KyreniaObjectFilters