import React, { useState, useEffect, FunctionComponent } from 'react';
import { Popover } from 'react-tiny-popover'
import classNames from 'classnames';

interface KyreniaObjectResultsProps {
  objects: any
  setSelectedObject: any
}

const KyreniaObjectResults: FunctionComponent<KyreniaObjectResultsProps> = ({objects, setSelectedObject}) => {
  const [isObjectTypePopoverOpen, setIsObjectTypePopoverOpen] = useState(false)
  const [isEventsPopoverOpen, setIsEventsPopoverOpen] = useState(false)
  const [isMaterialPopoverOpen, setIsMaterialPopoverOpen] = useState(false)
  const [isOriginPopoverOpen, setIsOriginPopoverOpen] = useState(false)

  const cellClass =  'text-nowrap d-none d-lg-table-cell'

  const setSelectedAndScroll = (event, id) => {
    event.preventDefault();
    window.scrollTo({top: 0, behavior: 'instant'});
    setSelectedObject(id)
  }

  return(
    <table className="model-table table table-striped table-hover align-middle">
      <thead>
        <tr>
          <th className="text-nowrap">
            ID
          </th>
          <th className={cellClass}>
            Object Type
            <Popover
              isOpen={isObjectTypePopoverOpen}
              positions={['bottom','left','top','right']}
              content={<div><div>Object Type can evolve over time with research. Here we present the latest recorded data. View an Object's history to see the evolution of thinking.</div></div>}
              onClickOutside={() => setIsObjectTypePopoverOpen(false)}>
              <i onClick={() => setIsObjectTypePopoverOpen(!isObjectTypePopoverOpen)} className="icon fa-solid fa-info-circle text-secondary ms-2"></i>
            </Popover>
          </th>
          <th className={cellClass}>
            Object Material
            <Popover
              isOpen={isMaterialPopoverOpen}
              positions={['bottom','left','top','right']}
              content={<div><div>Object Material can evolve over time with research. Here we present the latest recorded data. View an Object's history to see the evolution of thinking.</div></div>}
              onClickOutside={() => setIsMaterialPopoverOpen(false)}>
              <i onClick={() => setIsMaterialPopoverOpen(!isMaterialPopoverOpen)} className="icon fa-solid fa-info-circle text-secondary ms-2"></i>
            </Popover>
          </th>
          <th className={cellClass}>
            Origin
            <Popover
              isOpen={isOriginPopoverOpen}
              positions={['bottom','left','top','right']}
              content={<div><div>Origin can evolve over time with research. Here we present the latest recorded data. View an Object's history to see the evolution of thinking.</div></div>}
              onClickOutside={() => setIsOriginPopoverOpen(false)}>
              <i onClick={() => setIsOriginPopoverOpen(!isOriginPopoverOpen)} className="icon fa-solid fa-info-circle text-secondary ms-2"></i>
            </Popover>
          </th>
          <th className="text-nowrap">
            Events
            <Popover
              isOpen={isEventsPopoverOpen}
              positions={['bottom','left','top','right']}
              content={<div><div>Events include initial excavation, reclassification, or anytime a researcher edits information associated with the object.</div></div>}
              onClickOutside={() => setIsEventsPopoverOpen(false)}>
              <i onClick={() => setIsEventsPopoverOpen(!isEventsPopoverOpen)} className="icon fa-solid fa-info-circle text-secondary ms-2"></i>
            </Popover>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {objects.map((object) => {
          const hasResults = object.event_count && object.event_count > 0

          return(
            <tr key={object.obj_id} className={hasResults ? '' : 'disabled'}>
              <td>
                <strong>KS-{object.obj_id}</strong>
              </td>
              <td className={cellClass}>
                {object.current_type_name}
              </td>
              <td className={cellClass}>
                {object.current_material_name}
              </td>
              <td className={cellClass}>
                {object.current_origin_name}
              </td>
              <td className="text-nowrap">
                {hasResults ?
                  <span className="badge badge--dot badge--success bg-light text-muted">
                    {object.event_count}
                  </span>
                  :
                  <span className="badge bg-light text-muted">0 Results</span>
                }
              </td>
              <td className="actions">
                <a href="#" onClick={(e) => setSelectedAndScroll(e, object.id)} className={classNames('btn btn-sm', {'btn-primary': object.event_count > 0, 'btn-secondary': object.event_count <= 0})}>View</a>
              </td>
            </tr>
          )}
        )}
      </tbody>
    </table>
  )
}

export default KyreniaObjectResults