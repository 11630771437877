import React, { useState, useEffect, FunctionComponent } from 'react';
import KyreniaObjectFilters from './filters/KyreniaObjectFilters'
import KyreniaObjectResults from './results/KyreniaObjectResults'
import encodeQueryData from '../shared/api/encodeQueryData'

import { post, get } from '../shared/api/ServerRequest'
import ObjectShow from './ObjectShow';
import ObjectIndexActions from './results/ObjectIndexActions';
import Breadcrumbs from './results/Breadcrumbs';
import Pagination from './results/Pagination';
import ActiveFilters from './results/ActiveFilters'
import SetBodyScrollable from '../shared/ui/bodyScrollable'

interface ObjectsIndexProps {
  initialFilters?: any
  showAlert: boolean
  currentUserId?: number | null | undefined
}

const ObjectsIndex: FunctionComponent<ObjectsIndexProps> = ({initialFilters, currentUserId}) => {
  const [results, setResults] = useState<any>(null)
  const [filters, setFilters] = useState<any>(initialFilters || {})
  const [sections, setSections] = useState<any>([])
  const [selectedObject, setSelectedObject] = useState<any>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)

  const [searchVisible, setSearchVisible] = useState<boolean>(false)

  const handleSearchToggle = () => {
    setSearchVisible((current) => !current);
    SetBodyScrollable(searchVisible);
  }

  const removeFilterOption = (section, value) => {
    const newFilters = {...filters}
    newFilters[section] = newFilters[section].filter((e) => e !== value)
    if(newFilters[section].length == 0) { delete newFilters[section] }
    setFilters(newFilters)
  }

  const sectionsWithFilters = (): string[] => {
    return Object.keys(filters).filter((section) => sections.some((s) => s.id == section) && filters[section] && filters[section].length > 0)
  }

  const onPopState = (event) => {
    if(event.state && event.state.path == 'index') {
      setSelectedObject(null)
    } else if(event.state && event.state.path == 'show') {
      setSelectedObject(event.state.objectId)
    }
  }

  const setWindowHistory = () => {
    // Update the window URL with the filters
    const data = encodeQueryData(filters)
    let url = window.location.pathname
    if(data.length > 0) { url = url + "?" + data }
    window.history.replaceState({filters: filters, page: currentPage, path: 'index'}, "", url);
  }

  useEffect(() => {
    window.addEventListener('popstate', onPopState)
    return () => {
      window.removeEventListener('popstate', onPopState)
    };
  }, []);

  useEffect(() => {
    post('/objects/results', {filters: filters, page: currentPage}).then((response) => {
      setResults(response.objects)
      setTotalPages(response.pagination.total_pages)
      setWindowHistory()
    })
   }, [currentPage])

  useEffect(() => {
    if(currentPage != 1) {
      setCurrentPage(1)
    } else {
      post('/objects/results', {filters: filters, page: currentPage}).then((response) => {
        setResults(response.objects)
        setTotalPages(response.pagination.total_pages)
        setWindowHistory()
      })
    }
    post('/objects/filters', {filters: filters}).then((results) => {
      setSections(results.sections)
    })
  }, [filters])

  const exportData = () => {
    const data = encodeQueryData(filters)
    window.location.href = '/obj/results_export?filters=' + data;
  }

  return(
    <>
      {selectedObject ?
        <ObjectShow objectId={selectedObject} filters={filters} sections={sections} backToSearch={() => setSelectedObject(null)} currentUserId={currentUserId} />
      :
        <div className="grid grid--index grid--col-start">

          <div className="grid-nav">
            <div className={`grid-nav__item ${ searchVisible ? 'bg-primary text-white' : '' }`} onClick={handleSearchToggle}>
              <div className="icon">
                <i className="fa-light fa-search"></i>
              </div>
              <div className="label">
                Search
              </div>
            </div>
          </div>

          <div className={`grid__left grid__overlay ${searchVisible ? 'grid__overlay-show' : ''}`}>
            <div className="p-3">
              <KyreniaObjectFilters setFilters={setFilters} filters={filters} sections={sections} />
            </div>
          </div>

          <div className="grid__main bg-white">
            <div className="active-filters border-bottom bg-light">
              <ActiveFilters filters={filters} sections={sections} removeFilterOption={removeFilterOption} setFilters={setFilters}/>
            </div>

            <div>
              {results &&
                <div>
                  {sectionsWithFilters().length == 0 &&
                    <div className="d-none d-lg-flex alert alert-warning rounded-0 mb-0">
                      <i className="icon fa-solid fa-search me-3"></i>
                      <div>Filter and find objects using the filters on the left or scroll through the table below.</div>
                    </div>
                  }
                  <div className="table-responsive">
                    {loading &&
                      <div className="text-center" style={{height: '600px'}}>
                        <div className="spinner-border text-primary mt-5" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    }
                    {!loading &&
                      <KyreniaObjectResults objects={results} setSelectedObject={setSelectedObject} />
                    }
                    <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
                  </div>
                </div>
              }
            </div>
          </div>
          <ObjectIndexActions exportData={exportData} admin={!!currentUserId} />
        </div>
      }
      <Breadcrumbs objectId={selectedObject} backToSearch={() => setSelectedObject(null)} />
    </>
  )
}
export default ObjectsIndex