import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';

// import {
//   GlassMagnifier
// } from "react-image-magnifiers";

import { del } from '../../shared/api/ServerRequest';

import EventTable from './EventTable';
import EventTableEdit from './EventTableEdit'
import ExhibitionAdd from './ExhibitionAdd';
import ImageSearch from './ImageSearch';

const EventData: FunctionComponent<any> = ({event, setModalImage, setSelectedEvent, editMode, saveEvent, editedEventData, setEditedEventData, reloadEvent, admin, refsVisible}) => {
  if(event == null) { return null}

  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [exhibitionModalOpen, setExhibitionModalOpen] = useState(false)
  const [refsAreVisible, setRefsAreVisible] = useState<boolean>(refsVisible)

  const handleRefsToggle = () => {
    setRefsAreVisible((refsAreVisible) => !refsAreVisible);
  }

  useEffect(() => {
    setSelectedImgIndex(0)
    setImageModalOpen(false)
    setExhibitionModalOpen(false)
  }, [event])

  const downloadFile = (index) => {
    window.open(event.images[index].file_link)
  }

  const removeImage = (img) => {
    if(confirm("Are you sure you want to remove this reference from this event?")) {
      del(`/event/${event.id}/remove_image`, {image_id: img.id}).then((results) => {
        reloadEvent(event.id)
      })
    }
  }

  const closeImageSearchModal = (reload: boolean, newImages?: Array<any>) => {
    if(reload) {
      reloadEvent(event.id)
    }
    setImageModalOpen(false)
  }

  const [selectedImgIndex, setSelectedImgIndex] = useState<number>(0)
  const selectedImage = event.images.find((img, index) => index == selectedImgIndex)

  return(
    <>
      <div className="grid__main bg-white">
        <div className="col-header page-gutter">
          <strong>Event Details</strong>
        </div>
        <div className="inline-defs grid__scroller">
          {editMode &&
            <EventTableEdit event={event} saveEvent={saveEvent} eventData={editedEventData} setEventData={setEditedEventData} />
          }
          {!editMode &&
            <EventTable event={event} />
          }
        </div>
      </div>
      <div className={`grid__right grid__overlay ${refsVisible ? 'grid__overlay-show' : ''}`} onClick={handleRefsToggle}>
        <div className="col-header page-gutter d-flex justify-content-between">
          <div>
            <strong>Event References</strong>
          </div>
          <div className="d-flex justify-content-end">
            {event.images.length > 1 &&
              <div className={'btn-group d-flex' + (event.images.length < 2 ? " invisible" : "")}>
                {selectedImgIndex > 0 ?
                  <div className="btn btn-light btn-sm" role="button" onClick={(e) => setSelectedImgIndex(selectedImgIndex - 1)}>
                    <i className="fa-solid fa-angle-left"></i>
                    <span className="visually-hidden">Previous</span>
                  </div>
                  :
                  <div className="btn btn-light btn-sm btn-disabled" role="button">
                    <i className="fa-solid fa-angle-left"></i>
                    <span className="visually-hidden">Previous</span>
                  </div>
                }
                <div className="btn btn-light btn-sm">
                  {selectedImgIndex+1} / {event.images.length}
                </div>
                {selectedImgIndex < event.images.length - 1 ?
                  <div className="btn btn-light btn-sm" role="button" onClick={(e) => setSelectedImgIndex(selectedImgIndex + 1)}>
                    <span className="visually-hidden">Next</span>
                    <i className="fa-solid fa-angle-right"></i>
                  </div>
                  :
                  <div className="btn-disabled btn btn-light btn-sm" role="button">
                    <span className="visually-hidden">Next</span>
                    <i className="fa-solid fa-angle-right"></i>
                  </div>
                }
              </div>
            }
            { event.images.length > 0 &&
              <div className="btn-group">
                <button type="button" className="btn btn-light btn-sm dropdown-toggle ms-2" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fa-solid fa-angle-down"></i>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <div className="dropdown-item" onClick={(e) => downloadFile(selectedImgIndex)}>
                      Download
                    </div>
                  </li>
                  { admin && event.id > 0 && selectedImage &&
                    <li>
                      <div className="dropdown-item" onClick={() => removeImage(selectedImage)}>
                        Remove reference from event
                      </div>
                    </li>
                  }
                  {admin && event.id > 0 &&
                    <li>
                      <div className="dropdown-item" onClick={(e) => setImageModalOpen(true)}>
                        Add reference to event
                      </div>
                    </li>
                  }
                  {!editMode && admin && event.id > 0 &&
                    <li className="border-top pt-2 mt-2">
                      <div className="dropdown-item" onClick={(e) => setExhibitionModalOpen(true)}>
                        Add to exhibition
                      </div>
                    </li>
                  }
                </ul>
              </div>
            }
          </div>
        </div>
        <div className="grid__scroller media-gallery bg-dark">
          {admin && event.id == -1 &&
            <div className="text-center justify-content-center p-5">
              Once the event is saved you will be able to add references here.
            </div>
          }
          {admin && event.id > 0 && event.images.length == 0 &&
            <div className="text-center justify-content-center p-5">
              <div className="btn btn-primary btn-sm" onClick={(e) => setImageModalOpen(true)}>
                Add reference to event
              </div>
            </div>
          }
          {event.images.length > 0 &&
            <>
              <div className="images-container p-2">
                {event.images.map((img, index) =>
                  <div key={img.id}>
                    {["image", "pdf"].includes(img.type) &&
                      <>
                        <figure key={img.id} style={{ overflow: 'hidden'}}className={classNames({'d-none': index != selectedImgIndex})} onClick={(e) => {setModalImage(img); setSelectedEvent(event);}}>
                          <img src={img.md_link} className="w-100" title={`Alias: ${event.alias}, Event ID: ${event.event_id}`} rel="" /><br/>
                          {/* <GlassMagnifier
                            imageSrc={img.md_link}
                            imageAlt={`Event Reference for Alias: ${event.alias}, Event ID: ${event.event_id}`}
                            largeImageSrc={img.xl_link}
                          /> */}
                        </figure>
                      </>
                    }
                    {!["image", "pdf"].includes(img.type) &&
                      <>
                        <figure key={img.id} className={classNames({'d-none': index != selectedImgIndex})}>
                          <a href={img.file_link}>
                            <img src={img.md_link} className="w-100" title={`Alias: ${event.alias}, Event ID: ${event.event_id}`} rel="" /><br/>
                          </a>
                        </figure>
                      </>
                    }
                  </div>
                )}
              </div>
              <small className="d-block text-white bg-dark p-1">{selectedImage.file_name} / {selectedImage.human_file_size}</small>
            </>
          }
        </div>
      </div>
      {editMode || admin &&
        <ImageSearch isOpen={imageModalOpen} closeModal={closeImageSearchModal} event={event} />
      }
      {(editMode || admin) &&
        <ExhibitionAdd isOpen={exhibitionModalOpen} closeModal={() => setExhibitionModalOpen(false)} image={selectedImage} objectId={event.object_id} eventId={event.id}/>
      }
    </>
  )
}

export default EventData