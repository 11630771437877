import React, { FunctionComponent } from 'react';

const EventTable: FunctionComponent<any> = ({event}) => {
  if(event == null) { return null }

  const tableData = [
    ["Alias", event.alias],
    ["Event ID", event.event_id],
    ["Type", event.event_type_string],
    ["Site", event.site_string],
    ["Owner", event.associate_string],
    ["Editor",event.editor_string],
    ["Dates (start,end)",`${event.event_start_formatted} - ${event.event_end_formatted}`],
    ["Description", event.event_description],
    ["Object type", event.obj_type_string],
    ["Material", event.obj_material_string],
    ["Lat, Long", `${event.latitude}, ${event.longitude}`],
    ["Grid (x,y)",`${event.grid_x}, ${event.grid_y}`],
    ["Location (1,2,3)", `${event.loc_1}, ${event.loc_2}, ${event.loc_3}`],
    ["Size (w, l, h)", `${event.width_cm} cm, ${event.length_cm} cm, ${event.height_cm} cm`],
    ["Thickness", event.thickness_cm, "cm"],
    ["Weight",event.weight_kg,"kg"],
    ["Capacity",event.capacity_ltr, "lt" ]
  ]

  return(
    <>
      {tableData.map((ed) =>
        <div className="d-lg-flex align-items-lg-top border-bottom" key={ed[0]}>
          <div className="dt">{ed[0]}</div>
          <div className="dd">{ed[1]} {ed[2]}</div>
        </div>
      )}
    </>
  )
}

export default EventTable
