import React, { FunctionComponent, useState } from 'react';
import Modal, {ModalHeader, ModalBody} from '../../shared/ui/bootstrap/modal';
import { Document, Page, Outline, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.js";

const ImageSlider: FunctionComponent<any> = ({event, selectedImage, setSelectedImage}) => {
  if(event == null) { return null }

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(-1)
  const [loadProgress, setLoadProgress] = useState(0)
  const [isImageZoomed, setImageZoomed] = useState(false);

  const formatPercent = (ratio) => {
    return Math.round(ratio) + '%';
  }
  const downloadFile = (link) => {
    window.open(link)
  }

  const handleImageClick = () => {
    setImageZoomed(!isImageZoomed);
  };

  const downloadLink = selectedImage ? selectedImage.file_link : null

  return(
    <Modal isOpen={selectedImage != null} modalId={"image-gallery"} modalClasses="modal-fullscreen">
      <ModalHeader title="" onClose={(e) => setSelectedImage(null)} modalId="image-gallery">
        {downloadLink &&
          <div className="btn btn-outline-light" onClick={(e) => downloadFile(downloadLink)}>
          Download
          </div>
        }
      </ModalHeader>
      <ModalBody>
      {!isImageZoomed ? (
        <>
          <div className="splide__track">
            <ul className="splide__list">
              <li className="splide__slide" style={{width: '100%', flexBasis: '100%'}}>
                {selectedImage &&
                  <>
                    {selectedImage.type == "pdf" &&
                      <>
                        {totalPages > 0 &&
                          <div className="modal-toolbar">
                            <div className="btn-group">
                              {currentPage > 1 &&
                                <div onClick={() => setCurrentPage(currentPage-1)} className="btn btn-outline-light">
                                  <i className="icon fa fa-angle-left"></i>
                                  <span className="visually-hidden">Prev</span>
                                </div>
                              }
                              <div className="btn btn-outline-light">
                                Page {currentPage} / {totalPages}
                              </div>
                              {currentPage < totalPages &&
                                <div onClick={() => setCurrentPage(currentPage+1)} className="btn btn-outline-light">
                                  <span className="visually-hidden">Next</span>
                                  <i className="icon fa fa-angle-right"></i>
                                </div>
                              }
                            </div>
                          </div>
                        }
                        <Document file={selectedImage.file_link} loading={<div>Loading PDF {formatPercent(loadProgress)}</div>} onLoadProgress={({loaded, total}) => setLoadProgress((loaded / total) * 100) } onLoadSuccess={({numPages}) => { setTotalPages(numPages); setCurrentPage(1); }}>
                          <Outline onItemClick={({pageNumber: itemPageNumber}) => setCurrentPage(itemPageNumber)} />
                          <Page pageNumber={currentPage} />
                        </Document>
                      </>
                    }
                    {selectedImage.type == "image" &&
                      <div className="fullscreen" style={{ cursor: 'zoom-in' }} onClick={handleImageClick}>
                        <img src={selectedImage.xl_link} className="object-contain" />
                      </div>
                    }
                    {selectedImage.type != "image" && selectedImage.type != "pdf" &&
                      <div className="fullscreen">
                        <a href={selectedImage.file_link}>Download {selectedImage.file_name}</a>
                      </div>
                    }
                  </>
                }
              </li>
            </ul>
          </div>
          <ul id="thumbnails" className="thumbnails">
            {event.images.map((img) =>
              <li className="thumbnail" key={img.id} onClick={(e) => setSelectedImage(img)}>
                <img src={img.thumbnail_link} className="object-cover" />
              </li>
            )}
          </ul>
        </>
      ) : (
        <div onClick={handleImageClick} style={{ cursor: 'zoom-out' }}>
          {selectedImage &&
            <>
              {selectedImage.type == "image" &&
                <img src={selectedImage.xl_link} className="w-100" />
              }
            </>
          }
        </div>
      )}
      </ModalBody>
    </Modal>
  )
}

export default ImageSlider