import React, {useState, useRef, FunctionComponent, useEffect} from 'react'
import classNames from 'classnames'
import useClickOutside from '../click-outside'
import highlightPattern from '../highlight-pattern'
import escapeRegExp from '../../utils/escapeRegex'

interface AutocompleteProps {
  options: Array<any>,
  value: string
  onChange: any
  placeholderText: string
  emptyName?: string
  allowNewValues?: boolean
}


const Autocomplete : FunctionComponent<AutocompleteProps> = ({options, placeholderText, value, onChange, emptyName, allowNewValues}) => {
  const [searchValue, setSearchValue] = useState("")
 // const [hasFocus, setHasFocus] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState<any>(options)

  const dropdownRef = useRef(null)
  useClickOutside(dropdownRef, () => {
    if(showDropdown) {
      setShowDropdown(false)
    }
  });

  useEffect(() => {
    if(options && options.length > 0) {
      const initialOption = options.find((opt) => opt.value == value )
      if(initialOption) {
        setSearchValue(initialOption.label)
      }
    }
  }, [options])

  const onSearchChange = (value) => {
    if(allowNewValues) {
      setSearchValue(value)
      onChange(value)
    } else {
      setSearchValue(value)
    }
    const lowerSearchValue = value.toLowerCase()
    if(options) {
      const newOpts = options.filter((opt) => opt.label.toLowerCase().includes(lowerSearchValue) )
      setFilteredOptions(newOpts)
      setShowDropdown(true)
    }
  }

  const setHasFocus = () => {
    setShowDropdown(true)
    onSearchChange("")
  }

  const optionClick = (option) => {
    onChange(option.value)
    setShowDropdown(false)
  }

  if(!options) { return null }

  const showPlaceholder = !searchValue || searchValue.length == 0
  let selectedOption = options.find((opt) => opt.value == value )

  return (
    <div className="form-autocomplete">
      <input
        placeholder={showPlaceholder ? placeholderText : ""}
        className="form-control"
        type="text"
        value={showDropdown ? searchValue : (selectedOption?.label || "")}
        onChange={(e) => onSearchChange(e.target.value)}
        onFocus={(e) => setHasFocus()}
      />
      {filteredOptions && filteredOptions.length > 0 &&
        <div className={classNames('dropdown-menu', {show: showDropdown})} ref={dropdownRef}>
          <div className="dropdown-item" key={'blank'} onClick={() => optionClick({value: null})}>{emptyName != null ? emptyName : 'Any'}</div>
          {filteredOptions.map((opt) =>
            <div className="dropdown-item" key={opt.value} onClick={() => optionClick(opt)}>{highlightPattern(opt.label, new RegExp(escapeRegExp(searchValue), 'i'))}</div>
          )}
        </div>
      }
    </div>
  )
}

export default Autocomplete