import React, {useState, useRef, FunctionComponent, useEffect} from 'react'
import classNames from 'classnames'
import useClickOutside from '../click-outside'
import { post } from '../../api/ServerRequest'

interface AutocompleteUrlProps {
   url: string
   value: string
   onChange: any
   placeholderText: string
}

const AutocompleteUrl: FunctionComponent<AutocompleteUrlProps> = ({url, placeholderText, value, onChange}) => {
  const [searchValue, setSearchValue] = useState(value)
  const [hasFocus, setHasFocus] = useState(false)
  const [options, setOptions] = useState<any>([])
  const [showOptions, setShowOptions] = useState(false)
  //const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(-1)

  const dropdownRef = useRef(null)
  useClickOutside(dropdownRef, () => {
    if(showOptions) {
      setShowOptions(false)
      onChange(searchValue)
    }
  });

  const onSearchChange = () => {
    post(url, {q: searchValue}).then((response) => {
      setOptions(response.results)
      setShowOptions(true)
      if(response.results.length == 1) {
        onChange(response.results[0].value)
      }
    })
  }

  const optionClick = (value) => {
    onChange(value)
    setSearchValue(value)
    setShowOptions(false)
  }

  useEffect(() => {
    //setShowOptions(hasFocus)
  }, [hasFocus])

  useEffect(() => {
    if(value != searchValue) {
      setSearchValue(value)
    }
  }, [value])

  useEffect(() => {
    setShowOptions(true)
    onSearchChange()
  }, [searchValue])

  const showPlaceholder = searchValue.length == 0
  const showDropdown = showOptions && !(options.length == 1 && options[0].value == searchValue) && searchValue.length > 0

  return (
    <>
      <input
        placeholder={showPlaceholder ? placeholderText : ""}
        className="form-control"
        type="text"
        value={searchValue}
//        onFocus={(e) => setHasFocus(true)}
//        onBlur={(e) => setHasFocus(false)}
        onChange={(e) => setSearchValue(e.target.value)}
//        onKeyDown={handleKeyDown}
//        onKeyPress={handleKeyPress}
      />
      <div className={classNames('dropdown-menu', {show: showDropdown})} ref={dropdownRef}>
        {options.map((opt) =>
          <div className="dropdown-item" key={opt.value} onClick={() => optionClick(opt.value)}>{opt.label}</div>
        )}
        {options.length == 0 &&
          <div className="dropdown-item text-info no-hover">No Matches</div>
        }
      </div>
    </>
  )
}

export default AutocompleteUrl