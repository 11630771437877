//import "@hotwired/turbo-rails"

import "./shared/ui/offcanvas"

// --- React Components
import {mountReactComponents, registerReactComponent} from './shared/react/component-mounter';

import CatalogueIndex from "./components/CatalogueIndex";
registerReactComponent('CatalogueIndex', CatalogueIndex);

import ObjectsIndex from './components/ObjectsIndex'
registerReactComponent('ObjectsIndex', ObjectsIndex);

import ObjectShow from './components/ObjectShow';
registerReactComponent('ObjectShow', ObjectShow);

import MergeObjects from './components/MergeObjects';
registerReactComponent('MergeObjects', MergeObjects);

import ExhibitionShow from './components/ExhibitionShow';
registerReactComponent('ExhibitionShow', ExhibitionShow);

import CatalogueShow from './components/CatalogueShow';
registerReactComponent('CatalogueShow', CatalogueShow);

document.addEventListener('DOMContentLoaded', () => {
  mountReactComponents()
});

document.addEventListener('turbo:load', () => {
  mountReactComponents()
});
// --- End React Components