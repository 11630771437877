import React, {FunctionComponent, useEffect, useState} from 'react';
import classNames from 'classnames';
import { post } from '../../shared/api/ServerRequest';

import Autocomplete from '../../shared/ui/bootstrap/autocomplete';
import Modal, {ModalHeader, ModalBody, ModalFooter} from '../../shared/ui/bootstrap/modal';

import debounce from '../../shared/ui/debouce';

interface ImageSearchProps {
  event: any
  isOpen: boolean
  closeModal: any
}

const ImageSearch : FunctionComponent<ImageSearchProps> = ({closeModal, event, isOpen}) => {
  const [filterData, setFilterData] = useState<any>({})
  const [filters, setFilters] = useState<any>({})
  const [images, setImages] = useState<any>([])
  const [pageCount, setPageCount] = useState(0)
  const [currentPaage, setCurrentPage] = useState(0)
  const [selectedImages, setSelectedImages] = useState<any>([])

  useEffect(() => {
    setSelectedImages([])
  }, [isOpen])

  const mergeFilter = (data) => {
    setFilters({...filters, ...data})
  }

  const imageSelected = (img) => {
    return !!selectedImages.find((i) => i.id === img.id )
  }

  const toggleImageSelection = (img) => {
    if(imageSelected(img)) {
      const newImages = selectedImages.filter((i) => i.id !== img.id)
      setSelectedImages(newImages)
    } else {
      setSelectedImages(selectedImages.concat(img))
    }
  }

  const addImagesToEvent = () => {
    const selectedImageIds = selectedImages.map((img) => img.id).join(',')

    post(`/event/${event.id}/add_image`, {image_ids: selectedImageIds}).then((results) => {
      closeModal(true, results.images)
    })
  }

  useEffect(() => {
    post('/images/filter_data', {filters: filters}).then((results) => {
      setFilterData(results.filter_data)
    })
  }, [])

  useEffect(() => {
    debounce(() => {
      post('/images/search', {filters: filters}).then((results) => {
        setImages(results.images)
      //  setPageCount(results.page_count)
      //  setCurrentPage(results.current_page)
      })
    }, 300)
  }, [filters])

  return(
    <Modal isOpen={isOpen} modalId="image-search" modalClasses="modal-fullscreen">
      <ModalHeader modalId="image-search" onClose={(e) => closeModal(false, [])} title="Image Search"></ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-lg-3">
            <div className="f-card">
              <div className="f-card__heading p-3 d-flex justify-content-between align-items-center">
                <span className="f-card__heading-label">Filters</span>
              </div>

              <div className="px-3 mb-2">
                <div className="f-search">
                  <i className="icon fa-solid fa-search"></i>
                  <input className="form-control" value={filters.text || ""} onChange={(e) => mergeFilter({text: e.target.value})} placeholder="File Name" />
                </div>
              </div>

              <div className="px-3 mb-2">
                <div className="f-select">
                  <div className="form-floating">
                    <Autocomplete options={filterData.cat_sources} value={filters.catalogue_source} onChange={(value) => mergeFilter({catalogue_source: value})} placeholderText="Catalogue Source" />
                  </div>
                </div>
              </div>

              <div className="px-3 mb-2 ">
                <div className="f-select">
                  <div className="form-floating">
                    <Autocomplete options={filterData.catalogues} value={filters.catalogue_id} onChange={(value) => mergeFilter({catalogue_id: value})} placeholderText="Catalogue" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="row">
              {images && images.map((img) =>
                <div className="col-3 mb-4" key={img.id}>
                  <div className={classNames('card', {'border border-primary border-3': imageSelected(img)})} onClick={() => toggleImageSelection(img)}>
                    <figure className="position-relative mb-0">
                      <div className="ratio ratio-4x3 rounded-top" style={{'backgroundColor': '#aab6cd'}}>
                        <img src={img.md_link} className="rounded-top object-cover" />
                      </div>
                      <span className="image-badge position-absolute">
                        {img.reference_ids.length} Events
                      </span>
                    </figure>
                    <div className="py-2 px-3 text-dark">
                      <a href={img.md_link} target="_blank">{img.file_name}</a> / {img.catalogue_name}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button onClick={(e) => closeModal(false, [])} className="btn btn-light">Cancel</button>
        <button onClick={(e) => addImagesToEvent()} className="btn btn-primary">Add {selectedImages.length > 0 ? selectedImages.length : ""} Selected References To Event</button>
      </ModalFooter>
    </Modal>
  )
}

export default ImageSearch