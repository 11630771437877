import React, {FunctionComponent, useEffect, useState} from 'react';
import classNames from 'classnames';
import { post, get } from '../../shared/api/ServerRequest';

import Autocomplete from '../../shared/ui/bootstrap/autocomplete';
import Modal, {ModalHeader, ModalBody, ModalFooter} from '../../shared/ui/bootstrap/modal';

import debounce from '../../shared/ui/debouce';

interface EditEntryProps {
  entry: any
  isOpen: boolean
  closeModal: any
}

const EditEntry : FunctionComponent<EditEntryProps> = ({closeModal, entry, isOpen}) => {
  const [entryName, setEntryName] = useState<string>("")
  const [entryDescription, setEntryDescription] = useState<string>("")

  const removeEntry = () => {
    const data = {
      entry_id: entry.id
    }
    post('/exhibitions/remove_entry', data).then((result) => {
      closeModal(true)
    })
  }

  const updateEntry = () => {
    const data = {
      entry_id: entry.id,
      image_id: entry.image.id,
      name: entryName,
      description: entryDescription
    }
    post('/exhibitions/update_entry', data).then((result) => {
      closeModal(true)
    })
  }

  useEffect(() => {
    setEntryName(entry.name)
    setEntryDescription(entry.description)
  }, [entry])

  return(
    <Modal isOpen={isOpen} modalId="exhibitions-search" modalClasses="modal-fullscreen">
      <ModalHeader modalId="exhibitions-search" onClose={(e) => closeModal(false)}  title="Exhibitions"></ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-lg-3">
            <div className="f-card">
              <div className="f-card__heading p-3 d-flex justify-content-between align-items-center">
                <span className="f-card__heading-label">Edit</span>
              </div>
              <div className="px-3 mb-2">
                <div className="form-floating mb-3">
                  <input className="form-control" value={entryName} onChange={(e) => setEntryName(e.target.value)} />
                  <label>Name</label>
                </div>
                <div className="form-floating mb-3">
                  <textarea className="form-control" value={entryDescription} onChange={(e) => setEntryDescription(e.target.value)} style={{'height': '5rem'}}/>
                  <label>Description</label>
                </div>
              </div>
            </div>
            <div className="f-card p-3">
              <button onClick={(e) => updateEntry()} className="btn btn-primary w-100 mb-3">
                Update Information
              </button>
              <button onClick={(e) => removeEntry()} className="btn btn-danger w-100 mb-0">
                Remove from Exhibition
              </button>
            </div>
          </div>

          <div className="col-lg-9">
            <span key={entry.image.file_name}>
              {["image", "pdf"].includes(entry.image.type) &&
                <>
                  <figure key={entry.image.md_link}>
                    <img src={entry.image.md_link} />
                  </figure>
                </>
              }
              {!["image", "pdf"].includes(entry.image.type) &&
                <>
                  <figure key={entry.image.md_link}>
                    <a href={entry.image.file_link}>
                      <img src={entry.image.md_link} />
                    </a>
                  </figure>
                </>
              }
            </span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button onClick={(e) => closeModal(false)} className="btn btn-light">Cancel</button>
      </ModalFooter>
    </Modal>
  )
}

export default EditEntry