// https://blog.guisehn.com/implement-helper-react-rails-erb/
import React from 'react'
import ReactDOM from 'react-dom'

const components = {}

export const registerReactComponent = function(name, component) {
  // console.log("registered: " + name)
  components[name] = component
}

export const mountReactComponents = function() {
  // Get all tags generated by the `rails_component` helper.
  const mountingElements = document.querySelectorAll('[data-react-component]')
  if(mountingElements && mountingElements.length) {
    mountingElements.forEach((el) => {
      // Extract name and props from the data present in the element
      const { name, props } = JSON.parse(el.getAttribute('data-react-component'))
     // console.log("mounting: " + name)
      // Retrieve the component from the `components` object we mounted before,
      // and check if it exists.
      const component = components[name]
      if (component) {
        // Mount the component with the props inside the element
        console.log("mounting component" + name)
        ReactDOM.render(
          React.createElement(component, props || {}),
          el
        )
      } else {
        console.log("component not registered: " + name)
      }
    })
  }
}