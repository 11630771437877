import React, {FunctionComponent, useEffect, useState} from 'react';
import { post, get } from '../../shared/api/ServerRequest';

import Modal, {ModalHeader, ModalBody, ModalFooter} from '../../shared/ui/bootstrap/modal';

import debounce from '../../shared/ui/debouce';

interface EntryObjectsProps {
  entry: any
  isOpen: boolean
  closeModal: any
}

const EntryObjects : FunctionComponent<EntryObjectsProps> = ({closeModal, entry, isOpen}) => {
  const [objects, setObjects] = useState<any>([])

  const getObjects = () => {
    get(`/exhibition_entry/${entry.id}/objects`).then((response) => {
      setObjects(response.objects)
    });
  }

  useEffect(() => {
    setObjects([])
    if(isOpen) {
      getObjects()
    }
  }, [isOpen])

  const exhibitionObject = objects[0]
  const associatedObjects = objects.slice(1)

  return(
    <Modal isOpen={isOpen} modalId="exhibitions-search" modalClasses="modal-fullscreen">
      <ModalHeader modalId="exhibitions-search" onClose={(e) => closeModal(false)}  title="Exhibitions"></ModalHeader>
      <ModalBody>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-5 col-xl-4">
              <img src={entry.image.md_link} className="w-100 position-sticky" style={{top: 0}} />
            </div>
            <div className="col-12 col-lg-7 col-xl-8">
              {exhibitionObject &&
                <>
                  <h2 className="h4 mb-3">
                    Exhibition Object
                  </h2>
                  <table className="table table-dark table-sm">
                    <thead className="bg-dark">
                      <tr className="bg-dark">
                        <th className="bg-dark" style={{width: "6rem"}}>ID</th>
                        <th className="bg-dark" style={{width: "8rem"}}>Type</th>
                        <th className="bg-dark">Aliases</th>

                        <th className="bg-dark" style={{width: "6rem"}}>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          KS-{exhibitionObject.id}
                        </td>
                        <td>
                          {exhibitionObject.current_type_name}
                        </td>
                        <td>
                          {exhibitionObject.aliases.join(", ")}
                        </td>
                        <td>
                          <a href={`/objects/${exhibitionObject.id}`} target="_blank" className="btn btn-primary btn-sm">View</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              }

              {associatedObjects.length > 0 &&
                <>
                  <h2 className="h4 my-3">
                    Other Associated Objects
                  </h2>
                  <table className="table table-dark table-sm">
                    <thead className="bg-dark">
                      <tr className="bg-dark">
                        <th className="bg-dark" style={{width: "6rem"}}>ID</th>
                        <th className="bg-dark" style={{width: "8rem"}}>Type</th>
                        <th className="bg-dark">Aliases</th>
                        <th className="bg-dark" style={{width: "6rem"}}>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      {associatedObjects.map((object) =>
                        <tr key={object.id}>
                          <td>
                            KS-{object.id}
                          </td>
                          <td>
                            {object.current_type_name}
                          </td>
                          <td>
                            {object.aliases.join(", ")}
                          </td>
                          <td>
                            <a href={`/objects/${object.id}`} target="_blank" className="btn btn-primary btn-sm">View</a>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
              }
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button onClick={(e) => closeModal(false)} className="btn btn-light">Cancel</button>
      </ModalFooter>
    </Modal>
  )
}

export default EntryObjects